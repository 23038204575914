<template>
  <div class="layout-subcontainer">
    <h1 class="tit-h1">전화 상담이력관리</h1>
    <div class="box-wrap">
      <div class="box-ct d-flex">
        <div class>
          <div class="form-inp sm">
            <v-select
              v-model="selected_channel"
              :items="aspCustList"
              item-value="CD"
              item-text="CD_NM"
              outlined
              hide-details
              label="회사구분"
              v-on:change="[getConsultTY('*'), getConsultTY_Detail('*'), getCmmCode(), cngSelMenu(), getUserGroup()]"
              :readonly="RDOnly"
            >
              <template v-slot:label>
                회사구분
                <v-icon color="#EF9191">mdi-circle-small</v-icon>
              </template>
            </v-select>
          </div>
          <v-menu
            v-model="menu1"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
            content-class="calendar-modal"
            color="#FFF"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateRangeText"
                v-bind="attrs"
                v-on="on"
                readonly
                outlined
                hide-details
                class="form-inp icon-calendar ml-2"
                append-icon="svg-calendar"
              ></v-text-field>
            </template>
            <div class="datepicker-range">
              <v-date-picker
                v-model="dates[0]"
                no-title
                dark
                color="#F7AFAE"
                name="startDate"
                @change="startDate"
                :first-day-of-week="1"
                locale="ko-KR"
                :day-format="mixin_getDate"
              ></v-date-picker>
              <v-date-picker
                v-model="dates[1]"
                dark
                color="#F7AFAE"
                no-title
                show-current="false"
                :min="dates[0]"
                name="endDate"
                @change="endDate"
                :first-day-of-week="1"
                locale="ko-KR"
                :day-format="mixin_getDate"
              ></v-date-picker>
            </div>
            <div class="text-center pt-3 pb-3">
              <v-btn outlined class="btn-default" @click="menu1 = false"
                >확인</v-btn
              >
            </div>
          </v-menu>
          <div class="form-inp sm ml-2">
            <v-select
              v-model="calltypcd"
              :items="mixin_common_code_get(this.common_code, 'PLT025', '전체')"
              outlined
              hide-details
              label="콜유형"
              placeholder="선택하세요"
            ></v-select>
          </div>
          <!--
          <div class="form-inp sm ml-2">
            <v-select
              v-model="cnsltypcd"
              :items="srhCnslTypCd"
              item-text="CD_NM"
              item-value="CD"
              outlined
              hide-details
              label="상담유형1"
              @change="changeCounselTypeKey(selected_channel, cnsltypcd, '1')"
              placeholder="선택하세요"
            ></v-select>
          </div>
          <div class="form-inp sm ml-2">
            <v-select
              v-model="cnsltypcd2"
              :items="srhCnslTypCd2"
              item-text="CD_NM"
              item-value="CD"
              outlined
              hide-details
              label="상담유형2"
              @change="changeCounselTypeKey(selected_channel, cnsltypcd2, '2')"
              placeholder="선택하세요"
            ></v-select>
          </div>
          <div class="form-inp sm ml-2">
            <v-select
              v-model="cnsltypcd3"
              :items="srhCnslTypCd3"
              item-text="CD_NM"
              item-value="CD"
              outlined
              hide-details
              label="상담유형3"
              @change="changeCounselTypeKey(selected_channel, cnsltypcd3, '3')"
              placeholder="선택하세요"
            ></v-select>
          </div>
          <div class="form-inp sm ml-2">
            <v-select
              v-model="cnsltypcd4"
              :items="srhCnslTypCd4"
              item-text="CD_NM"
              item-value="CD"
              outlined
              hide-details
              label="상담유형4"
              placeholder="선택하세요"
            ></v-select>
          </div>
          -->
          <div class="form-inp sm ml-2">
            <v-select
              v-if = "SEL_1"
              v-model="MODEL_1"
              :disabled="!SEL_1"
              :items="ITEM_1"
              :item-text="ITEM_TEXT_1"
              :item-value="ITEM_VALUE_1"
              outlined
              hide-details
              :label="TEXT_1"
              placeholder="선택하세요"
              @change="getConsultTY('1')"
            ></v-select>
          </div>
          <div class="form-inp sm ml-2">
            <v-select
              v-if = "SEL_2"
              v-model="MODEL_2"
              :disabled="!SEL_2"
              :items="ITEM_2"
              :item-text="ITEM_TEXT_2"
              :item-value="ITEM_VALUE_2"
              outlined
              hide-details
              :label="TEXT_2"
              placeholder="선택하세요"
              @change="getConsultTY('2')"
            ></v-select>
          </div>
          <div class="form-inp sm ml-2">
            <v-select
              v-if = "SEL_3"
              v-model="MODEL_3"
              :disabled="!SEL_3"
              :items="ITEM_3"
              :item-text="ITEM_TEXT_3"
              :item-value="ITEM_VALUE_3"
              outlined
              hide-details
              :label="TEXT_3"
              placeholder="선택하세요"
              @change="getConsultTY('3')"
            ></v-select>
          </div>
          <div class="form-inp sm ml-2">
            <v-select
              v-if = "SEL_4"
              v-model="MODEL_4"
              :disabled="!SEL_4"
              :items="ITEM_4"
              :item-text="ITEM_TEXT_4"
              :item-value="ITEM_VALUE_4"
              outlined
              hide-details
              :label="TEXT_4"
              placeholder="선택하세요"
            ></v-select>
          </div>
          <div class="form-inp sm ml-2">
            <v-select
              v-if = "SEL_5"
              v-model="MODEL_5"
              :disabled="!SEL_5"
              :items="ITEM_5"
              :item-text="ITEM_TEXT_5"
              :item-value="ITEM_VALUE_5"
              outlined
              hide-details
              :label="TEXT_5"
              placeholder="선택하세요"
            ></v-select>
          </div>
          <div class="form-inp sm ml-2">
            <v-select
              v-if = "SEL_6"
              v-model="MODEL_6"
              :disabled="!SEL_6"
              :items="ITEM_6"
              :item-text="ITEM_TEXT_6"
              :item-value="ITEM_VALUE_6"
              outlined
              hide-details
              :label="TEXT_6"
              placeholder="선택하세요"
            ></v-select>
          </div>
          <div class="form-inp sm ml-2">
            <v-select
              v-if = "SEL_7"
              v-model="MODEL_7"
              :disabled="!SEL_7"
              :items="ITEM_7"
              :item-text="ITEM_TEXT_7"
              :item-value="ITEM_VALUE_7"
              outlined
              hide-details
              :label="TEXT_7"
              placeholder="선택하세요"
            ></v-select>
          </div>
          <div class="form-inp sm ml-2">
            <v-select
              v-if = "SEL_8"
              v-model="MODEL_8"
              :disabled="!SEL_8"
              :items="ITEM_8"
              :item-text="ITEM_TEXT_8"
              :item-value="ITEM_VALUE_8"
              outlined
              hide-details
              :label="TEXT_8"
              placeholder="선택하세요"
            ></v-select>
          </div>
          <div class="mt-2">
            <!-- 고객조회조건 변경
            <v-text-field
              class="form-inp sm"
              name="userId"
              label="고객 ID"
              v-model="userId"
              outlined
              hide-details
            ></v-text-field>
            -->
            <v-text-field
              class="form-inp sm"
              name="userPhoneNo"
              v-model="userPhoneNo"
              label="고객전화번호"
              outlined
              hide-details
            ></v-text-field>
            <v-text-field
              class="form-inp sm ml-2"
              name="userNm"
              label="고객 이름"
              v-model="userNm"
              outlined
              hide-details
            ></v-text-field>
            <!-- 조회 버튼 -->
            <v-btn
              v-if="this.mixin_set_btn(this.$options.name, 'btnPopupCus')"
              dark
              small
              class="btn-main-search pa-0 ml-2"
              min-width="32px"
              height="32px"
              plain
              @click="btnCus(), showDialog('MP01')"
            >
              <v-icon small class="svg-main-searh white"></v-icon>
            </v-btn>
            <!-- 새로고침 버튼 -->
            <v-btn
              v-if="this.mixin_set_btn(this.$options.name, 'btnInitCus')"
              dark
              small
              class="btn-main-refresh pa-0 ml-2"
              min-width="32px"
              height="32px"
              @click="clear0"
              plain
            >
              <v-icon small class="svg-main-refresh"></v-icon>
            </v-btn>
            <div class="form-inp free ml-2">
              <v-select
                single-line
                v-model="selected_userGroup"
                :items="userGroup"
                item-value="CD"
                item-text="CD_NM"
                outlined
                label="팀"
                multiple
                hide-details
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index < 1" small>
                    <span>{{ item.CD_NM }}</span>
                    <template>
                      <v-btn
                        icon
                        x-small
                        class="svg-close20 ml-1"
                        v-show="dropItemRemove === true"
                        @click.stop="removeDropGroup(index)"
                      ></v-btn>
                    </template>
                  </v-chip>
                  <span v-if="index === 0" class="grey--text text-caption">
                    ...
                  </span>
                </template>
              </v-select>
            </div>
            <v-text-field
              class="form-inp sm ml-2"
              name="userId1"
              label="사용자 ID"
              v-model="userId1"
              outlined
              hide-details
            ></v-text-field>
            <v-text-field
              class="form-inp sm ml-2"
              name="userNm1"
              label="사용자 이름"
              v-model="userNm1"
              outlined
              hide-details
            ></v-text-field>
            <v-text-field
              class="form-inp sm ml-2"
              name="userNick1"
              label="사용자 닉네임"
              v-model="userNick1"
              outlined
              hide-details
            ></v-text-field>
            <!-- 조회 버튼 -->
            <v-btn
              v-if="this.mixin_set_btn(this.$options.name, 'btnPopupUser')"
              dark
              small
              class="btn-main-search pa-0 ml-2"
              min-width="32px"
              height="32px"
              plain
              @click="btnUser(), showDialog('MP02')"
            >
              <v-icon small class="svg-main-searh white"></v-icon>
            </v-btn>
            <!-- 새로고침 버튼 -->
            <v-btn
              v-if="this.mixin_set_btn(this.$options.name, 'btnInitUser')"
              dark
              small
              class="btn-main-refresh pa-0 ml-2"
              min-width="32px"
              height="32px"
              @click="clear1"
              plain
            >
              <v-icon small class="svg-main-refresh"></v-icon>
            </v-btn>
          <div class="form-inp sm ml-2">
            <v-select
              v-if = "SEL_9"
              v-model="MODEL_9"
              :disabled="!SEL_9"
              :items="ITEM_9"
              :item-text="ITEM_TEXT_9"
              :item-value="ITEM_VALUE_9"
              outlined
              hide-details
              :label="TEXT_9"
              placeholder="선택하세요"
            ></v-select>
          </div>
          <div class="form-inp sm ml-2">
            <v-select
              v-if = "SEL_10"
              v-model="MODEL_10"
              :disabled="!SEL_10"
              :items="ITEM_10"
              :item-text="ITEM_TEXT_10"
              :item-value="ITEM_VALUE_10"
              outlined
              hide-details
              :label="TEXT_10"
              placeholder="선택하세요"
            ></v-select>
          </div>
          </div>
          <div class="form-inp free">
            <div class="form-inp free">
              <v-select
                single-line
                v-model="CHK_NEWTIME"
                :items="CHK_TIME"
                item-value="value"
                item-text="text"
                outlined
                label="상담일자"
                multiple
                hide-details
                v-on:change="[chgChkTime(),chkNoTime()]"
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index < 4" small>
                    <span>{{ item.text }}</span>
                    <template>
                      <v-btn
                        icon
                        x-small
                        class="svg-close20 ml-1"
                        v-show="dropItemRemove === true"
                        @click.stop="removeDropTime(item)"
                      ></v-btn>
                    </template>
                  </v-chip>
                  <span v-if="index === 3" class="grey--text text-caption">
                    ...
                  </span>
                </template>
              </v-select>
            </div>
            <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnAllCheck')" outlined class="btn-default ml-2" @click="allClick"
              >전체선택</v-btn
            >
            <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnAllUncheck')" outlined class="btn-default ml-2" @click="[allCloseClick(),chkNoTime()]"
              >전체해제</v-btn
            >
            <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnWork')" outlined class="btn-default ml-2" @click="selectTime"
              >업무시간선택</v-btn
            >
            <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnTurn')" outlined class="btn-default ml-2" @click="selectTurn"
              >선택반전</v-btn
            >
          </div>
            <div class="form-inp free ml-2">
            <v-text-field
              class="form-inp sm ml-2"
              name="CALL_TIME"
              v-model="CALL_TIME"
              label="녹음시간"
              suffix="초"
              outlined
              hide-details
            ></v-text-field>
            </div>
            <div class="form-inp sm ml-2">
              <v-select
                v-model="SEL_NEWUPDOWN"
                :items="SEL_UPDOWN"
                item-value="CD"
                item-text="CD_NM"
                outlined
                hide-details
                label="범위"
              >
                <template v-slot:label>
                  범위
                </template>
              </v-select>
            </div>
            <div class="form-inp sm ml-2">
              <v-select
                class="form-inp full"
                :items="CNSL_RESULT_LIST"
                outlined
                hide-details
                placeholder="선택하세요"
                v-model="SRH_RESULT"
                >
                <template v-slot:label>
                  상담결과
                </template>
              </v-select>
            </div>
        </div>
        <div class="ml-auto align-self-center">
        <v-btn
        :disabled = "nextDisabled"
              dark
              small
              class="btn-main-search pa-0 ml-2"
              min-width="32px"
              height="32px"
              plain
              @click="search('N','next')"
            >다음</v-btn>
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSearch')" outlined class="btn-etc2 ml-3" v-on:click="search('N')">조회</v-btn>
        </div>
      </div>
    </div>
    <!-- 상담이력조회 목록 -->
    <div class="box-wrap">
      <h2 class="tit-h2 d-flex">
        상담이력관리 목록
        <div class="ml-auto">
          <v-text-field
            class="form-inp sm ml-2"
            name="SEL_DATA_NUM"
            v-model="SEL_DATA_NUM"
            label="엑셀 데이터 개수"
            outlined
            hide-details
          ></v-text-field>
          <v-btn
            v-if="this.mixin_set_btn(this.$options.name, 'btnExcelDown')"
            outlined
            class="btn-point ml-2"
            @click="search('Y')"
          >
            엑셀다운로드
          </v-btn>
        </div>
      </h2>
      <div class="box-ct">
        <v-data-table
          dense
          height="320px"
          :headers="gridDataHeaders"
          :items="gridDataText"
          :items-per-page="itemsPerPage"
          item-key="ROW_NUMBER"
          :page.sync="page"
          hide-default-footer
          class="grid-default"
          calculate-widths
          @page-count="pageCount = $event"
          @click:row="detail"
          id="consultListTable"
          single-select
          no-data-text="상담이력이 없습니다."
          v-model="SELECTROW"
          :item-class="isActiveRow"
          fixed-header
        >
          <template v-slot:item.CNSL_DATE="{ item }">
            <div>{{ dateFormat(item.CNSL_DATE) }}</div>
          </template>
          <template v-slot:item.CNSL_BEGIN_TIME="{ item }">
            <div>{{ timeFormat(item.CNSL_BEGIN_TIME) }}</div>
          </template>
          <template v-slot:item.CNSL_EOT_TIME="{ item }">
            <div>{{ timeFormat(item.CNSL_EOT_TIME) }}</div>
          </template>
          <template v-slot:item.CUST_NO="{ item }">
            <div>{{ juminFormat(item.CUST_NO) }}</div>
          </template>
          <template v-slot:item.CUST_TEL_NO="{ item }">
            <div>{{ phoneHyphen(item.CUST_TEL_NO) }}</div>
          </template>
        </v-data-table>
        <div class="grid-paging text-center pt-2">
          <v-pagination
            v-model="page"
            :length="pageCount"
            :total-visible="totalVisible"
            next-icon="svg-paging-next"
            prev-icon="svg-paging-prev"
          ></v-pagination>
        </div>

        <!--다운로드 받을 테이블-->
        <v-data-table
          dense
          height="0px"
          :headers="gridDataHeaders"
          :items="downGridDataText"
          single-select
          item-key="ROW_NUMBER"
          :items-per-page="SEL_DATA_NUM == '' ? pagination.rowsPerPage : SEL_DATA_NUM"
          :page.sync="page"
          hide-default-footer
          class="grid-default"
          id="downTableMng"
        >
        </v-data-table>
        <!--보이지 않는 테이블-->
      </div>
    </div>
    <!--// 고객상세정보 -->
    <div calss>
      <v-dialog
        max-width="1400"
        max-height="1400"
        v-model="dialogMP02"
        content-class="square-modal"
      >
        <dialog-MP02
          headerTitle="사용자정보"
          @hide="hideDialog('MP02')"
          :userInfo="userInfo"
          @input="userInfo = $event"
          @selectUser="[hideDialog('MP02'), setUser()]"
        ></dialog-MP02>
        <template v-slot:body></template>
      </v-dialog>
    </div>

    <div calss>
      <v-dialog
        max-width="1400"
        max-height="1400"
        v-model="dialogMP01"
        content-class="square-modal"
      >
        <dialog-MP01
          headerTitle="고객정보"
          @hide="hideDialog('MP01')"
          :cusInfo="cusInfo"
          @input="cusInfo = $event"
          @selectCus="[hideDialog('MP01'), setCus()]"
        ></dialog-MP01>
        <template v-slot:body></template>
      </v-dialog>
    </div>
    <!--// 상담이력조회 목록 -->
    <div class="box-wrap">
      <h2 class="tit-h2 d-flex">녹취재생</h2>
      <v-card-text>
        <v-row class="black--text">
          <span v-if="RDWT_FILE_NM == '' || RDWT_FILE_NM ==null">녹취파일이 없거나 파일을 재생할 수 없습니다.</span>
          <audio 
            v-if="RDWT_FILE_NM != '' && RDWT_FILE_NM !=null"
            controls="" 
            :autoplay="false"
            name="media"
            :src="REC_CALL"
            type="audio/mpeg"
          >
          </audio>
        </v-row>
      </v-card-text>
    </div>
    <!-- 상담이력상세정보 -->
    <div class="box-wrap">
      <h2 class="tit-h2 d-flex">
        상담이력 상세정보
        <div class="ml-auto">
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnCancle')" outlined class="btn-etc ml-2" v-on:click="cancel">취소</v-btn>
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnReqAccess')" outlined class="btn-point ml-2" @click="showConfirm"
            >저장</v-btn
          >
        </div>
      </h2>
      <div class="box-ct">
        <div class="table-form">
          <table v-if="selected_channel != 'FUNU'">
            <caption class="hide">
              상담이력 상세정보
            </caption>
            <colgroup>
              <col width="108px" />
              <col width="226px" />
              <col width="108px" />
              <col width="226px" />
              <col width="208px" />
              <col width="186px" />
              <col width="108px" />
              <col width="226px" />
              <col width="108px" />
              <col width="" />
            </colgroup>
            <tbody>
              <tr>
                <th scope="row">상담시작일자</th>
                <td>
                  <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    content-class="calendar-modal"
                    dark
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        hide-details
                        class="form-inp sm icon-calendar full"
                        append-icon="svg-calendar"
                        disabled
                        :value="dateFormat(rowData.CNSL_DATE)"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      color="#F7AFAE"
                      no-title
                      @input="menu2 = false"
                      disabled
                      :first-day-of-week="1"
                      locale="ko-KR"
                      :day-format="mixin_getDate"
                    ></v-date-picker>
                  </v-menu>
                </td>
                <th scope="row">상담시작시간</th>
                <td>
                  <v-menu
                    v-model="menu5"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    content-class="calendar-modal"
                    dark
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        hide-details
                        class="form-inp sm icon-calendar full"
                        append-icon="svg-time"
                        disabled
                        :value="timeFormat(rowData.CNSL_BEGIN_TIME)"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-model="timeStep"
                      :allowed-minutes="allowedStep"
                      color="#F7AFAE"
                      format="24hr"
                      @input="menu5 = false"
                      disabled
                    ></v-time-picker>
                  </v-menu>
                </td>
                <th scope="row">콜유형</th>
                <td>
                  <v-select
                    class="form-inp sm"
                    :items="
                      mixin_common_code_get(this.common_code, 'PLT007', '전체')
                    "
                    outlined
                    hide-details
                    label=""
                    placeholder="선택하세요"
                    v-model="rowData.CALL_TY"
                    readonly
                  ></v-select>
                </td>
                <th scope="row">고객전화번호</th>
                <td>
                  <v-text-field
                    class="form-inp sm"
                    :disabled="true"
                    outlined
                    hide-details
                    :value="phoneHyphen(rowData.CUST_TEL_NO)"
                  ></v-text-field>
                </td>
                <th scope="row">발신번호</th>
                <td>
                  <v-text-field
                    class="form-inp sm"
                    v-model="rowData.SEND_MAN_NO"
                    :disabled="true"
                    outlined
                    hide-details
                  ></v-text-field>
                </td>
              </tr>
              <tr>
                <th scope="row">상담종료일자</th>
                <td>
                  <v-menu
                    v-model="menu3"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    content-class="calendar-modal"
                    dark
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        hide-details
                        class="form-inp sm icon-calendar full"
                        append-icon="svg-calendar"
                        disabled
                        :value="dateFormat(rowData.CNSL_EOT_DATE)"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      color="#F7AFAE"
                      no-title
                      @input="menu3 = false"
                      disabled
                      :first-day-of-week="1"
                      locale="ko-KR"
                      :day-format="mixin_getDate"
                    ></v-date-picker>
                  </v-menu>
                </td>
                <th scope="row">상담종료시간</th>
                <td>
                  <v-menu
                    v-model="menu5"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    content-class="calendar-modal"
                    dark
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        hide-details
                        class="form-inp sm icon-calendar full"
                        append-icon="svg-time"
                        disabled
                        :value="timeFormat(rowData.CNSL_EOT_TIME)"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-model="timeStep"
                      :allowed-minutes="allowedStep"
                      color="#F7AFAE"
                      format="24hr"
                      @input="menu5 = false"
                      disabled
                    ></v-time-picker>
                  </v-menu>
                </td>
                <td>
                  <v-text-field
                    class="form-inp sm"
                    v-model="rowData.CSLT_NM"
                    :disabled="true"
                    outlined
                    hide-details
                  >
                    <template v-slot:label>
                      사용자명
                    </template>
                  </v-text-field>
                </td>
                <td><v-select
                    class="form-inp full"
                    :items="CNSL_RESULT_LIST"
                    outlined
                    hide-details
                    placeholder="선택하세요"
                    v-model="CNSL_RESULT"
                  >
                    <template v-slot:label>
                      상담결과
                    </template>
                  </v-select></td>
                <th scope="row" rowspan="2">특이사항<br>/비고</th>
                <td rowspan="2" colspan="3">
                  <v-textarea
                    class="textarea-type0"
                    v-model="ETC"
                    outlined
                    hide-details
                    no-resize
                  ></v-textarea>
                </td>
              </tr>
              <tr>
                <th scope="row">
                  문의내용
                </th>
                <td><v-select
                    class="form-inp full"
                    :items="CNSL_CONT_LIST"
                    outlined
                    hide-details
                    label=""
                    placeholder="선택하세요"
                    v-model="CNSL_CONT"
                  >
                    <template v-slot:label>
                      문의내용
                    </template>
                  </v-select></td>
                <td>
                  <v-select
                    class="form-inp full"
                    :items="VIS_PATH_LIST"
                    outlined
                    hide-details
                    label=""
                    placeholder="선택하세요"
                    v-model="VIS_PATH"
                  >
                    <template v-slot:label>
                      내원경로
                    </template>
                  </v-select>
                </td>
                <td colspan="3">
                  <v-select
                    class="form-inp sm"
                    :items="VIS_BOOK_LIST"
                    outlined
                    hide-details
                    label=""
                    placeholder="선택하세요"
                    v-model="VIS_BOOK"
                  >
                    <template v-slot:label>
                      상담내원
                    </template>
                  </v-select>
                  <v-menu v-model="menu7" :close-on-content-click="false" :nudge-right="40"
                    transition="scale-transition" offset-y min-width="auto" content-class="calendar-modal" color="#FFF">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="VIS_BOOK_DT" v-bind="attrs" v-on="on" outlined hide-details
                        class="form-inp icon-calendar ml-2 pt-0" append-icon="svg-calendar"></v-text-field>
                    </template>
                    <div class="d-flex align-center">
                      <v-date-picker v-model="date2" dark color="#F7AFAE" no-title show-current="false"
                        :first-day-of-week="1" locale="ko-KR" :day-format="mixin_getDate"></v-date-picker>
                      <v-time-picker v-model="timeStep2" :allowed-minutes="allowedStep" color="#F7AFAE" format="24hr">
                      </v-time-picker>
                    </div>
                    <div class="text-center pt-3 pb-3">
                      <v-btn outlined small class="btn-point ml-3" @click="menu7 = false">확인</v-btn>
                    </div>
                  </v-menu>
                  <v-select
                    class="form-inp sm ml-2"
                    :items="VIS_MANAGE_LIST"
                    outlined
                    hide-details
                    label=""
                    placeholder="선택하세요"
                    v-model="VIS_MANAGE"
                  >
                    <template v-slot:label>
                      상담내원담당
                    </template>
                  </v-select>
                </td>
              </tr>
              <tr>
                <th scope="row">진료항목</th>
                <td>
                  <v-select
                    class="form-inp full"
                    :items="DISA_ITEM_LIST"
                    outlined
                    hide-details
                    label=""
                    placeholder="선택하세요"
                    v-model="DISA_ITEM"
                  >
                    <template v-slot:label>
                      진료항목
                    </template>
                  </v-select>
                </td>
                <td>
                  <v-select
                    class="form-inp full"
                    :items="CONDITION_LIST"
                    outlined
                    hide-details
                    label=""
                    placeholder="선택하세요"
                    v-model="CONDITION"
                  >
                    <template v-slot:label>
                      상태
                    </template>
                  </v-select>
                </td>
                <td colspan="3">
                  <v-select
                    class="form-inp sm"
                    :items="OPER_BOOK_LIST"
                    outlined
                    hide-details
                    label=""
                    placeholder="선택하세요"
                    v-model="OPER_BOOK"
                  >
                    <template v-slot:label>
                      수술예약
                    </template>
                  </v-select>
                  <v-menu v-model="menu8" :close-on-content-click="false" :nudge-right="40"
                  transition="scale-transition" offset-y min-width="auto" content-class="calendar-modal" color="#FFF">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="OPER_BOOK_DT" v-bind="attrs" v-on="on" outlined hide-details
                      class="form-inp icon-calendar ml-2 pt-0" append-icon="svg-calendar"></v-text-field>
                  </template>
                  <div class="d-flex align-center">
                    <v-date-picker v-model="date3" dark color="#F7AFAE" no-title show-current="false"
                      :first-day-of-week="1" locale="ko-KR" :day-format="mixin_getDate"></v-date-picker>
                    <v-time-picker v-model="timeStep3" :allowed-minutes="allowedStep" color="#F7AFAE" format="24hr">
                    </v-time-picker>
                  </div>
                  <div class="text-center pt-3 pb-3">
                    <v-btn outlined small class="btn-point ml-3" @click="menu8 = false">확인</v-btn>
                  </div>
                  </v-menu>
                  <v-select
                    class="form-inp sm ml-2"
                    :items="OPER_MANAGE_LIST"
                    outlined
                    hide-details
                    label=""
                    placeholder="선택하세요"
                    v-model="OPER_MANAGE"
                  >
                    <template v-slot:label>
                      수술예약담당
                    </template>
                  </v-select>
                </td>
                <th scope="row" rowspan="2">상담내용</br></th>
                <td rowspan="2" colspan="3">
                  <v-textarea
                    class="textarea-type0"
                    label
                    :value="rowData.CNSL_CNTN"
                    v-model="CNSL_CNTN"
                    no-resize
                    outlined
                  ></v-textarea>
                </td>
              </tr>
              <tr>
                <th scope="row">상담예약</th>
                <td scope="row">
                <v-select
                    class="form-inp full"
                    :items="CNSL_BOOK_LIST"
                    outlined
                    hide-details
                    label=""
                    placeholder="선택하세요"
                    v-model="CNSL_BOOK"
                  >
                    <template v-slot:label>
                      문의예약
                    </template>
                  </v-select>
                </td>
                <td>
                  <v-menu v-model="menu6" :close-on-content-click="false" :nudge-right="40"
                  transition="scale-transition" offset-y min-width="auto" content-class="calendar-modal" color="#FFF">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="CNSL_BOOK_DT" v-bind="attrs" v-on="on" outlined hide-details
                      class="form-inp icon-calendar ml-2 pt-0" append-icon="svg-calendar"></v-text-field>
                  </template>
                  <div class="d-flex align-center">
                    <v-date-picker v-model="date1" dark color="#F7AFAE" no-title show-current="false"
                      :first-day-of-week="1" locale="ko-KR" :day-format="mixin_getDate"></v-date-picker>
                    <v-time-picker v-model="timeStep1" :allowed-minutes="allowedStep" color="#F7AFAE" format="24hr">
                    </v-time-picker>
                  </div>
                  <div class="text-center pt-3 pb-3">
                    <v-btn outlined small class="btn-point ml-3" @click="menu6 = false">확인</v-btn>
                  </div>
                  </v-menu>
                </td>
                <td colspan="2">
                  <v-select
                    class="form-inp sm"
                    :items="OPERATION_LIST"
                    outlined
                    hide-details
                    label=""
                    placeholder="선택하세요"
                    v-model="OPERATION"
                  >
                    <template v-slot:label>
                      수술
                    </template>
                  </v-select>
                  
                  <v-menu v-model="menu9" :close-on-content-click="false" :nudge-right="40"
                    transition="scale-transition" offset-y min-width="auto" content-class="calendar-modal" color="#FFF">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="OPERATION_DT" v-bind="attrs" v-on="on" outlined hide-details
                        class="form-inp icon-calendar ml-2 pt-0" append-icon="svg-calendar"></v-text-field>
                    </template>
                    <div class="d-flex align-center">
                      <v-date-picker v-model="date4" dark color="#F7AFAE" no-title show-current="false"
                        :first-day-of-week="1" locale="ko-KR" :day-format="mixin_getDate"></v-date-picker>
                      <v-time-picker v-model="timeStep4" :allowed-minutes="allowedStep" color="#F7AFAE" format="24hr">
                      </v-time-picker>
                    </div>
                    <div class="text-center pt-3 pb-3">
                      <v-btn outlined small class="btn-point ml-3" @click="menu9 = false">확인</v-btn>
                    </div>
                  </v-menu>
                </td>
                <td>
                  <v-text-field
                    class="form-inp full"
                    v-model="OPERATION_COST"
                    outlined
                    hide-details
                    label="수술금액"
                  >
                  </v-text-field>
                </td>
              </tr>
            </tbody>
          </table>
          <table v-if="selected_channel == 'FUNU'">
            <caption class="hide">
              상담이력 상세정보
            </caption>
            <colgroup>
              <col width="108px" />
              <col width="226px" />
              <col width="108px" />
              <col width="226px" />
              <col width="108px" />
              <col width="226px" />
              <col width="108px" />
              <col width="226px" />
              <col width="108px" />
              <col width="" />
            </colgroup>
            <tbody>
              <tr>
                <th scope="row">상담시작일자</th>
                <td>
                  <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    content-class="calendar-modal"
                    dark
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        hide-details
                        class="form-inp sm icon-calendar full"
                        append-icon="svg-calendar"
                        disabled
                        :value="dateFormat(rowData.CNSL_DATE)"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      color="#F7AFAE"
                      no-title
                      @input="menu2 = false"
                      disabled
                      :first-day-of-week="1"
                      locale="ko-KR"
                      :day-format="mixin_getDate"
                    ></v-date-picker>
                  </v-menu>
                </td>
                <th scope="row">상담시작시간</th>
                <td>
                  <v-menu
                    v-model="menu5"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    content-class="calendar-modal"
                    dark
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        hide-details
                        class="form-inp sm icon-calendar full"
                        append-icon="svg-time"
                        disabled
                        :value="timeFormat(rowData.CNSL_BEGIN_TIME)"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-model="timeStep"
                      :allowed-minutes="allowedStep"
                      color="#F7AFAE"
                      format="24hr"
                      @input="menu5 = false"
                      disabled
                    ></v-time-picker>
                  </v-menu>
                </td>
                <th scope="row">콜유형</th>
                <td>
                  <v-select
                    class="form-inp sm"
                    :items="
                      mixin_common_code_get(this.common_code, 'PLT007', '전체')
                    "
                    outlined
                    hide-details
                    label=""
                    placeholder="선택하세요"
                    v-model="rowData.CALL_TY"
                    readonly
                  ></v-select>
                </td>
                <th scope="row">고객전화번호</th>
                <td>
                  <v-text-field
                    class="form-inp sm"
                    :disabled="true"
                    outlined
                    hide-details
                    :value="phoneHyphen(rowData.CUST_TEL_NO)"
                  ></v-text-field>
                </td>
                <th scope="row">발신번호</th>
                <td>
                  <v-text-field
                    class="form-inp sm"
                    v-model="rowData.SEND_MAN_NO"
                    :disabled="true"
                    outlined
                    hide-details
                  ></v-text-field>
                </td>
              </tr>
              <tr>
                <th scope="row">상담종료일자</th>
                <td>
                  <v-menu
                    v-model="menu3"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    content-class="calendar-modal"
                    dark
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        hide-details
                        class="form-inp sm icon-calendar full"
                        append-icon="svg-calendar"
                        disabled
                        :value="dateFormat(rowData.CNSL_EOT_DATE)"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      color="#F7AFAE"
                      no-title
                      @input="menu3 = false"
                      disabled
                      :first-day-of-week="1"
                      locale="ko-KR"
                      :day-format="mixin_getDate"
                    ></v-date-picker>
                  </v-menu>
                </td>
                <th scope="row">상담종료시간</th>
                <td>
                  <v-menu
                    v-model="menu5"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    content-class="calendar-modal"
                    dark
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        hide-details
                        class="form-inp sm icon-calendar full"
                        append-icon="svg-time"
                        disabled
                        :value="timeFormat(rowData.CNSL_EOT_TIME)"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-model="timeStep"
                      :allowed-minutes="allowedStep"
                      color="#F7AFAE"
                      format="24hr"
                      @input="menu5 = false"
                      disabled
                    ></v-time-picker>
                  </v-menu>
                </td>
                <th scope="row">사용자명</th>
                <td>
                  <v-text-field
                    class="form-inp sm"
                    v-model="rowData.CSLT_NM"
                    :disabled="true"
                    outlined
                    hide-details
                  ></v-text-field>
                </td>
                <th>녹취파일명</th>
                <td colspan="3">
                  <v-text-field
                    class="form-inp full"
                    v-model="RDWT_FILE_NM"
                    :disabled="true"
                    outlined
                    hide-details
                  ></v-text-field>
                </td>
              </tr>
              <tr>
                <th scope="row">
                  상담유형1
                </th>
                <td><v-select
                    class="form-inp full"
                    :items="CNSL_LV_1_DET_LIST"
                    item-text="CD_NM"
                    item-value="CD"
                    outlined
                    hide-details
                    label=""
                    placeholder="선택하세요"
                    v-model="CNSL_LV_1_DET"
                    @change="getConsultTY_Detail('1')"
                  >
                    <template v-slot:label>
                      상담유형1
                    </template>
                  </v-select></td>
                <th scope="row">
                  상담유형2
                </th>
                <td>
                  <v-select
                    class="form-inp sm"
                    :items="CNSL_LV_2_DET_LIST"
                    item-text="CD_NM"
                    item-value="CD"
                    outlined
                    hide-details
                    label=""
                    placeholder="선택하세요"
                    v-model="CNSL_LV_2_DET"
                    @change="getConsultTY_Detail('2')"
                  >
                    <template v-slot:label>
                      상담유형2
                    </template>
                  </v-select>
                </td>
                <th scope="row">
                  상담유형3
                </th>
                <td>
                  <v-select
                    class="form-inp sm"
                    :items="CNSL_LV_3_DET_LIST"
                    item-text="CD_NM"
                    item-value="CD"
                    outlined
                    hide-details
                    label=""
                    placeholder="선택하세요"
                    v-model="CNSL_LV_3_DET"
                    @change="getConsultTY_Detail('3')"
                  >
                    <template v-slot:label>
                      상담유형3
                    </template>
                  </v-select>
                </td>
                <th scope="row" rowspan="2">상담내용</th>
                <td rowspan="2" colspan="3">
                  <v-textarea
                    class="textarea-type0"
                    label
                    :value="rowData.CNSL_CNTN"
                    v-model="CNSL_CNTN"
                    no-resize
                    outlined
                  ></v-textarea>
                </td>
              </tr>
                <th scope="row">
                  상담유형4
                </th>
                <td><v-select
                    class="form-inp full"
                    :items="CNSL_LV_4_DET_LIST"
                    item-text="CD_NM"
                    item-value="CD"
                    outlined
                    hide-details
                    label=""
                    placeholder="선택하세요"
                    disabled
                    v-model="CNSL_LV_4_DET"
                  >
                    <template v-slot:label>
                      상담유형4
                    </template>
                  </v-select></td>
                <th scope="row">
                </th>
                <td>
                </td>
                <th scope="row">
                </th>
                <td>
                </td>
              <tr>
                <th scope="row">
                </th>
                <td>
                </td>
                <th scope="row">
                </th>
                <td>
                </td>
                <th scope="row">
                </th>
                <td>
                </td>
                <th scope="row" rowspan="2">특이사항<br>/비고</br></th>
                <td colspan="3" rowspan="2">
                  <v-textarea
                    class="textarea-type0"
                    label
                    :value="rowData.ETC"
                    v-model="ETC"
                    no-resize
                    outlined
                  ></v-textarea>
                </td>
              </tr>
              <tr>
                <th scope="row">
                </th>
                <td>
                </td>
                <th scope="row">
                </th>
                <td>
                </td>
                <th scope="row">
                </th>
                <td>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!--// 고객상세정보 -->
  </div>
</template>

<script>
import { mixin } from "@/mixin/mixin.js";
import { mapGetters } from "vuex";
import XLSX from "xlsx";
import dialogMP01 from "./MP01.vue";
import dialogMP02 from "./MP02.vue";
import api from "../../store/apiUtil.js";
export default {
  name: "MENU_M110202", //name은 'MENU_' + 파일명 조합
  mixins: [mixin],
  components: {
    dialogMP01,
    dialogMP02,
  },
  data: () => ({
    nextDisabled: true, //다음버튼 활성화 여부
    CNSL_TYP_CD: "",
    CNSL_TYP_CD_2: "",
    CNSL_TYP_CD_3: "",
    CNSL_TYP_CD_4: "",
    PROC_MDCLS_NM: "",
    CHNG_REQ_CNTN: "",
    CNSL_CNTN: "",
    RDWT_FILE_NM: "",
    CALL_TY: "",
    dialogMP01: false,
    dialogMP02: false,
    cusInfo: {},
    userInfo: {},
      params: { userId: "", userPhoneNo: '',userNm: "", aspNewcustKey: "" },
    common_code: [],
    menu1: false,
    menu2: false,
    menu3: false,
    menu4: false,
    menu5: false,
    menu6: false,
    menu7: false,
    menu8: false,
    menu9: false,
    timeStep: "00:00",
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
    selected_channel: "",
    search_channel: "",
    selected_userGroup: [],
    calltypcd: "",
    cnsltypcd: "",
    cnsltypcd2: "",
    cnsltypcd3: "",
    cnsltypcd4: "",
    userId: "",
      userPhoneNo:'',
    userNm: "",
    userId1: "",
    userNm1: "",
    userNick1: "",
    selected_userGroup: "",
    srhCallTypCd: [], //콜유형
    srhCnslTypCd: [], //상담유형
    srhCnslTypCd2: [], //상담유형2
    srhCnslTypCd3: [], //상담유형3
    srhCnslTypCd4: [], //상담유형4
    detailSrhCnslTypCd: [], //상담유형
    detailSrhCnslTypCd2: [], //상담유형2
    detailSrhCnslTypCd3: [], //상담유형3
    detailSrhCnslTypCd4: [], //상담유형4
    rowData: {}, //상세내역정보
    dates: [
      new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
    ],
    userGroup: [], //사용자그룹

    gridDataHeaders: [
      {
        text: "NO",
        value: "ROW_NUMBER",
        align: "center",
        width: "70px",
        sortable: true,
      },
      {
        text: "상담일자",
        value: "CNSL_DATE",
        align: "center",
        sortable: true,
        width: "100px",
      },
      {
        text: "콜 시작시간",
        value: "CNSL_BEGIN_TIME",
        align: "center",
        sortable: true,
        width: "100px",
      },
      {
        text: "콜 종료시간",
        value: "CNSL_EOT_TIME",
        align: "center",
        sortable: true,
        width: "100px",
      },
      {
        text: "통화시간",
        value: "TEL_TIME",
        align: "center",
        sortable: true,
        width: "100px",
      },
      {
        text: "콜유형",
        value: "CALL_TY_NM",
        align: "center",
        sortable: true,
        width: "100px",
      },
      {
        text: "콜기준여부",
        value: "RDWT_SEND_YN",
        align: " d-none",
        sortable: true,
        width: "100px",
      },
      {
        text: "통화자",
        value: "PROC_CODE_SCLS_NM",
        align: "center",
        sortable: true,
        width: "100px",
      },
      {
        text: "상담원명",
        value: "CSLT_NM",
        align: "center",
        sortable: true,
        width: "100px",
      },
      {
        text: "고객명",
        value: "CUST_NM",
        align: "center",
        sortable: true,
        width: "120px",
        cellClass: "ellipse ",
      },
      {
        text: "고객주민번호",
        value: "CUST_NO",
        align: " d-none",
        sortable: true,
        width: "120px",
      },
      {
        text: "발신자번호",
        value: "CUST_TEL_NO",
        align: "center",
        sortable: true,
        width: "100px",
      },
      //{ text: "상담사ID", value: "CSLT_ID", align: " d-none", sortable: true, width: "100px", },
        //{ text: "상담유형", value: "CNSL_TYP_CD", align: " d-none", sortable: true, width: "100px", },
        {
          align: ' d-none',
          show: false,
        },
        {
          align: ' d-none',
          show: false,
        },
        // { text: "상담유형2", value: "CNSL_TYP_CD_2", align: " d-none", sortable: true, width: "100px", },
        {
          align: ' d-none',
          show: false,
        },
        //{ text: "상담유형3", value: "CNSL_TYP_CD_3", align: " d-none", sortable: true, width: "100px", },
        {
          align: ' d-none',
          show: false,
        },
        //{ text: "상담유형4", value: "CNSL_TYP_CD_4", align: " d-none", sortable: true, width: "100px", },
        //{ text: "완료코드", value: "PROC_LCLS_NM", align: " d-none", sortable: true, width: "100px", },
        //{ text: "미완료사유", value: "PROC_MDCLS_NM", align: " d-none", sortable: true, width: "100px", },
        {
          text: "카테고리",
          value: 'CATEGORY_NM',
          align: "center",
          sortable: true,
          width: "100px",
        },
        {
          text: "상담채널",
          value: 'CNSL_CHN_1_NM',
          align: "center",
          sortable: true,
          width: "100px",
        },
        {
          text: "상담예약",
          value: 'CNSL_BOOK_NM',
          align: "center",
          sortable: true,
          width: "100px",
        },
        {
          text: "진료항목",
          value: 'DISA_ITEM_NM',
          align: "center",
          sortable: true,
          width: "100px",
        },
        {
          text: "상담유형1",
          value: 'CNSL_TYP_NM',
          align: "center",
          sortable: true,
          width: "100px",
        },
        {
          text: "상담유형2",
          value: 'CNSL_TYP_NM_2',
          align: "center",
          sortable: true,
          width: "100px",
        },
        {
          text: "상담유형3",
          value: 'CNSL_TYP_NM_3',
          align: "center",
          sortable: true,
          width: "100px",
        },
        {
          text: "상담유형4",
          value: 'CNSL_TYP_NM_4',
          align: "center",
          sortable: true,
          width: "100px",
        },
      {
        text: "완료여부",
        value: "PROC_NM",
        align: "center",
        sortable: true,
        width: "100px",
      },
      {
        text: "녹취파일명",
        value: "RDWT_FILE_NM",
        align: "center",
        sortable: true,
        width: "100px",
        cellClass: "ellipse ",
      },
      {
        text: "상담내용",
        value: "CNSL_CNTN",
        align: "center",
        sortable: true,
        width: "100px",
        cellClass: "ellipse ",
      },
    ],
    gridDataText: [],
    page: 1,
    pageCount: 0,
    itemsPerPage: 50,
    totalVisible: 10,
    pagination: { page: 1, rowsPerPage: 500, sortBy: "", descending: "" },
    textareaRules: [(v) => v.length <= 200 || "Max 200 characters"],
    textareaValue: "200자 제한!",

    SELECTROW: [],

    CHK_TIME: [
        {
          text: "00시",
          selected: true,
          value: "00",
        },
        {
          text: "01시",
          selected: true,
          value: "01",
        },
        {
          text: "02시",
          selected: true,
          value: "02",
        },
        {
          text: "03시",
          selected: true,
          value: "03",
        },
        {
          text: "04시",
          selected: true,
          value: "04",
        },
        {
          text: "05시",
          selected: true,
          value: "05",
        },
        {
          text: "06시",
          selected: true,
          value: "06",
        },
        {
          text: "07시",
          selected: true,
          value: "07",
        },
        {
          text: "08시",
          selected: true,
          value: "08",
        },
        {
          text: "09시",
          selected: true,
          value: "09",
        },
        {
          text: "10시",
          selected: true,
          value: "10",
        },
        {
          text: "11시",
          selected: true,
          value: "11",
        },
        {
          text: "12시",
          selected: true,
          value: "12",
        },
        {
          text: "13시",
          selected: true,
          value: "13",
        },
        {
          text: "14시",
          selected: true,
          value: "14",
        },
        {
          text: "15시",
          selected: true,
          value: "15",
        },
        {
          text: "16시",
          selected: true,
          value: "16",
        },
        {
          text: "17시",
          selected: true,
          value: "17",
        },
        {
          text: "18시",
          selected: true,
          value: "18",
        },
        {
          text: "19시",
          selected: true,
          value: "19",
        },
        {
          text: "20시",
          selected: true,
          value: "20",
        },
        {
          text: "21시",
          selected: true,
          value: "21",
        },
        {
          text: "22시",
          selected: true,
          value: "22",
        },
        {
          text: "23시",
          selected: true,
          value: "23",
        },
      ],

      CHK_NEWTIME: [
        "00",
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
      ],
      dropItemRemove: true,

      CALL_TIME:'',

      SEL_UPDOWN:[{
        CD: 'UP',
        CD_NM:'이상'
      },
      {
        CD: 'DOWN',
        CD_NM:'이하'
      }
      ],
      SEL_NEWUPDOWN:'',

      downGridDataText:[],
      SEL_DATA_NUM:'',

      date1:new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
      date2:new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
      date3:new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
      date4:new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
      timeStep1: "00:00",
      timeStep2: "00:00",
      timeStep3: "00:00",
      timeStep4: "00:00",

      CNSL_CONT:'',
      VIS_PATH:'',
      DISA_ITEM:'',
      CNSL_BOOK:'',
      //CNSL_BOOK_DT:'',
      CONS_BOOK:'',
      //CONS_BOOK_DT:'',
      OPER_BOOK:'',
      //OPER_BOOK_DT:'',
      OPERATION:'',
      //OPERATION_DT:'',
      ETC:'',
      OPERATION_COST:'',
      VIS_BOOK:'',
      //VIS_BOOK_DT:'',
      VIS_MANAGE:'',
      OPER_MANAGE:'',
      CONDITION:'',

      REC_CALL:'',

      SRH_CNSL_CHN_1:'',
      SRH_CNSL_BOOK:'',
      SRH_DISA_ITEM:'',
      SRH_CONDITION:'',
      SRH_CATEGORY:'',

      CNSL_CHN_1_LIST:[{
        text:'전체',
        value:''
      }],
      CONDITION_LIST:[{
        text:'전체',
        value:''
      }],
      CATEGORY_LIST:[{
        text:'전체',
        value:''
      }],
      CNSL_CONT_LIST:[{
        text:'전체',
        value:''
      }],
      VIS_PATH_LIST:[{
        text:'전체',
        value:''
      }],
      DISA_ITEM_LIST:[{
        text:'전체',
        value:''
      }],
      CNSL_BOOK_LIST:[{
        text:'전체',
        value:''
      }],
      CONS_BOOK_LIST:[{
        text:'전체',
        value:''
      }],
      OPER_BOOK_LIST:[{
        text:'전체',
        value:''
      }],
      OPERATION_LIST:[{
        text:'전체',
        value:''
      }],
      VIS_BOOK_LIST:[{
        text:'전체',
        value:''
      }],
      VIS_MANAGE_LIST:[{
        text:'전체',
        value:''
      }],
      OPER_MANAGE_LIST:[{
        text:'전체',
        value:''
      }],

      CNSL_RESULT_LIST:[{
        text:'전체',
        value:''
      }],
      CNSL_RESULT:'',
      SRH_RESULT:'',

      // 콜관련 회사별 공통코드 조회
      cmmnCdByCus: ["MTC001", "MTC002", "MTC003", "MTC004", "MTC005", "MTC006", "MTC007", "MTC008", "MTC009", "MTC010", "MTC011", "MTC012"],  
      cmmnCdLstByCus: [], 
      
      RDOnly:false,

      TEXT_1:'',
      TEXT_2:'',
      TEXT_3:'',
      TEXT_4:'',
      TEXT_5:'',
      TEXT_6:'',
      TEXT_7:'',
      TEXT_8:'',
      TEXT_9:'',
      TEXT_10:'',

      SEL_1:false,
      SEL_2:false,
      SEL_3:false,
      SEL_4:false,
      SEL_5:false,
      SEL_6:false,
      SEL_7:false,
      SEL_8:false,
      SEL_9:false,
      SEL_10:false,

      MODEL_1:'',
      MODEL_2:'',
      MODEL_3:'',
      MODEL_4:'',
      MODEL_5:'',
      MODEL_6:'',
      MODEL_7:'',
      MODEL_8:'',
      MODEL_9:'',
      MODEL_10:'',

      ITEM_1:'',
      ITEM_2:'',
      ITEM_3:'',
      ITEM_4:'',
      ITEM_5:'',
      ITEM_6:'',
      ITEM_7:'',
      ITEM_8:'',
      ITEM_9:'',
      ITEM_10:'',

      ITEM_TEXT_1: 'text',
      ITEM_TEXT_2: 'text',
      ITEM_TEXT_3: 'text',
      ITEM_TEXT_4: 'text',
      ITEM_TEXT_5: 'text',
      ITEM_TEXT_6: 'text',
      ITEM_TEXT_7: 'text',
      ITEM_TEXT_8: 'text',
      ITEM_TEXT_9: 'text',
      ITEM_TEXT_10: 'text',

      ITEM_VALUE_1: 'value',
      ITEM_VALUE_2: 'value',
      ITEM_VALUE_3: 'value',
      ITEM_VALUE_4: 'value',
      ITEM_VALUE_5: 'value',
      ITEM_VALUE_6: 'value',
      ITEM_VALUE_7: 'value',
      ITEM_VALUE_8: 'value',
      ITEM_VALUE_9: 'value',
      ITEM_VALUE_10: 'value',

      VALUE_1:'',
      VALUE_2:'',
      VALUE_3:'',
      VALUE_4:'',
      VALUE_5:'',
      VALUE_6:'',
      VALUE_7:'',
      VALUE_8:'',
      VALUE_9:'',
      VALUE_10:'',

      CMM_1:'',
      CMM_2:'',
      CMM_3:'',
      CMM_4:'',
      CMM_5:'',
      CMM_6:'',
      CMM_7:'',
      CMM_8:'',
      CMM_9:'',
      CMM_10:'',

      ALLI_1:' d-none',
      ALLI_2:' d-none',
      ALLI_3:' d-none',
      ALLI_4:' d-none',
      ALLI_5:' d-none',
      ALLI_6:' d-none',
      ALLI_7:' d-none',
      ALLI_8:' d-none',
      ALLI_9:' d-none',
      ALLI_10:' d-none',

      CHG_1:'',
      CHG_2:'',
      CHG_3:'',
      CHG_4:'',
      CHG_5:'',
      CHG_6:'',
      CHG_7:'',
      CHG_8:'',
      CHG_9:'',
      CHG_10:'',

      //퓨처누리 변수
      CNSL_LV_1:'',
      CNSL_LV_2:'',
      CNSL_LV_3:'',
      CNSL_LV_4:'',
      CNSL_LV_1_LIST:[{
        text:'전체',
        value:''
      }],
      CNSL_LV_2_LIST:[{
        text:'전체',
        value:''
      }],
      CNSL_LV_3_LIST:[{
        text:'전체',
        value:''
      }],
      CNSL_LV_4_LIST:[{
        text:'전체',
        value:''
      }],

      CNSL_LV_1_DET:'',
      CNSL_LV_2_DET:'',
      CNSL_LV_3_DET:'',
      CNSL_LV_4_DET:'',
      CNSL_LV_1_DET_LIST:[{
        text:'전체',
        value:''
      }],
      CNSL_LV_2_DET_LIST:[{
        text:'전체',
        value:''
      }],
      CNSL_LV_3_DET_LIST:[{
        text:'전체',
        value:''
      }],
      CNSL_LV_4_DET_LIST:[{
        text:'전체',
        value:''
      }],

      cmmnCdFUNU: [
        "FUNU001",
        "FUNU002",
        "FUNU003",
        "FUNU004",
        "FUNU005",
        "FUNU006",
        "FUNU007",
        "FUNU008",
        "FUNU009",
        "FUNU010",
      ],
      cmmnCdLstFUNU: [], // 콜관련공통코드_공통코드 조회 값
  }),

  async mounted() {
    //공통코드설정
    let codeName = ['CO001', "PLT007", "PLT025", "CO001", "CO002", "MTC00", "MTC001", "MTC002", "MTC003", "MTC004", "MTC005", "MTC006", "MTC007", "MTC008", "MTC009", "MTC010", "MTC011"];
    this.common_code = await this.mixin_common_code_get_all(codeName);

    /* 회사별 공통코드 조회 */
    this.cmmnCdLstByCus = await this.mixin_common_code_get_all(this.cmmnCdByCus);

    /* 퓨처누리 공통코드 조회 */
    this.cmmnCdLstFUNU = await this.mixin_common_code_get_all(this.cmmnCdFUNU);

    this.CNSL_RESULT_LIST = this.mixin_common_code_get(this.common_code, 'CO001', '전체');           // 콜상담저장_상담통합 상담결과
    /*
    api.post("/api/palette/common/get-cmm/list", {
        INFO1: "대분류",
        ASP_NEWCUST_KEY: this.selected_channel,
        ASP_USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
        ASP_CUST_KEY: this.selected_channel,
        USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
      },
      { 
        head: {
          SERVICE: "palette.common.get-cmm",
          METHOD: "list",
          TYPE: "BIZ_SERVICE",
        } 
      })
      .then((ret) => {
        let LV_DATA = ret.data.DATA;
        let LV_DATA_LENG = LV_DATA.length;
        let i = 0;
        this.CNSL_LV_1_LIST = [{
          text:'전체',
          value:''
        }];
        for(i;i<LV_DATA_LENG;i++){
          this.CNSL_LV_1_LIST.push({
            text: LV_DATA[i].CD_NM,
            value: LV_DATA[i].GROUP_CD
          })
        }
      });
      */

    //상담유형
    this.srhCnslTypCd = await this.mixin_counsel_type_code_get(
      this.selected_channel,
      "*",
      "전체"
    );
    this.srhCnslTypCd2.push({ CD_NM: "전체", CD: "" });
    this.srhCnslTypCd3.push({ CD_NM: "전체", CD: "" });
    this.srhCnslTypCd4.push({ CD_NM: "전체", CD: "" });

    this.userGroup = await this.mixin_attr_get(
      this.selected_channel,
      "B",
      "전체"
    );
    console.log(this.$store.getters["userStore/GE_USER_ROLE"]);

    let chk = this.mixin_check_AspCustKey_number();
    if(chk == 'Y'){
      this.selected_channel = this.$store.getters["userStore/GE_USER_ROLE"].company[0].CD;
      this.RDOnly = true;
      this.getCmmCode();
      this.cngSelMenu();
      this.getConsultTY_Detail('*')
      this.getUserGroup();
    } else {
      this.RDOnly = false;
    }

    this.getConsultTY('*');
  },

  methods: {
    btnUser() {
      var user = {
        ASP_CUST_KEY: this.selected_channel,
        USER_ID: this.userId1,
        USER_NM: this.userNm1,
        USER_NICK: this.userNick1,
      };
      this.userInfo = user;
    },
    setUser() {
      this.userId1 = this.userInfo.USER_ID;
      this.userNm1 = this.userInfo.USER_NM;
      this.userNick1 = this.userInfo.USER_NICK;
    },
    btnCus() {
      var cust = {
        ASP_CUST_KEY: this.selected_channel,
        CUST_ID: this.userId,
        PHONE_NUM: this.userPhoneNo.replaceAll("-", ""),
        CUST_NM: this.userNm,
      };
      this.cusInfo = cust;
    },
    setCus() {
      this.userId = this.cusInfo.CUST_ID;
      this.userNm = this.cusInfo.CUST_NM;
      this.userPhoneNo = this.cusInfo.CUSTOMER_PHONE_NO;
    },
    clear0() {
      this.userId = "";
      this.userNm = "";
      this.userPhoneNo = '';
    },
    clear1() {
      this.userId1 = "";
      this.userNm1 = "";
      this.userNick1 = "";
    },
    startDate(e) {
      this.dates[0] = e;
    },
    endDate(e) {
      this.dates[1] = e;
    },

    allowedStep: (m) => m % 10 === 0,
    phoneHyphen(number) {
      //핸드폰 번호 형식 만들기
      if (!number) return "";
      var phoneNum = number.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
      return phoneNum;
    },
    dateFormat(date) {
      //날짜 포멧
      if (!date) return "";
      var newDate = "";

      //공백제거
      date = date.replace(/\s/gi, "");

      if (date.length == 8) {
        newDate = date.replace(/(\d{4})(\d{2})(\d{2})/, "$1/$2/$3");
        return newDate;
      } else {
        return date;
      }
    },
    juminFormat(num) {
      //주민 번호 형식 만들기
      if (!num) return "";

      var juminNum = num.replace(/-/g, "").replace(/(\d{6})(\d{7})/g, "$1-$2");
      return juminNum;
    },

    timeFormat(time) {
      //접수시간 포맷
      var newTime = "";
      if (!time) return "";

      if (time.length == 6) {
        newTime =
          time.substring(0, 2) +
          ":" +
          time.substring(2, 4) +
          ":" +
          time.substring(4, 6);
        return newTime;
      } else {
        //추후 6자리 외의 데이터가 있을 경우 수정
        return time;
      }
    },

    showDialog(type) {
      if (this.selected_channel == "" || this.selected_channel == undefined) {
        this.showAlert("회사를 먼저 선택해주세요.");
        return;
      }

      //팝업 파라미터 설정
      this.popupParams;
      this[`dialog${type}`] = true;
    },
    hideDialog(type) {
      this[`dialog${type}`] = false;
    },
    submitDialog(type) {
      this[`dialog${type}`] = false;
    },

    //상담유형ChangeEvent
    async changeCounselTypeKey(asp_cust_key, spst_counsel_typ_cd, data_type) {
      if (data_type === "0") {
        this.srhCnslTypCd = await this.mixin_counsel_type_code_get(
          asp_cust_key,
          "*",
          "전체"
        );
        this.cnsltypcd2 = "";
        this.cnsltypcd3 = "";
        this.cnsltypcd4 = "";
      }
      if (data_type === "1") {
        this.cnsltypcd2 = "";
        this.cnsltypcd3 = "";
        this.cnsltypcd4 = "";
        this.srhCnslTypCd2 = [];
        this.srhCnslTypCd3 = [];
        this.srhCnslTypCd4 = [];
        this.srhCnslTypCd2.push({ CD_NM: "전체", CD: "" });
        this.srhCnslTypCd3.push({ CD_NM: "전체", CD: "" });
        this.srhCnslTypCd4.push({ CD_NM: "전체", CD: "" });
        if (spst_counsel_typ_cd !== "") {
          this.srhCnslTypCd2 = await this.mixin_counsel_type_code_get(
            asp_cust_key,
            spst_counsel_typ_cd,
            "전체"
          );
        }
      } else if (data_type === "2") {
        this.cnsltypcd3 = "";
        this.cnsltypcd4 = "";
        this.srhCnslTypCd3 = [];
        this.srhCnslTypCd4 = [];
        this.srhCnslTypCd3.push({ CD_NM: "전체", CD: "" });
        this.srhCnslTypCd4.push({ CD_NM: "전체", CD: "" });
        if (spst_counsel_typ_cd !== "") {
          this.srhCnslTypCd3 = await this.mixin_counsel_type_code_get(
            asp_cust_key,
            spst_counsel_typ_cd,
            "전체"
          );
        }
      } else if (data_type === "3") {
        this.cnsltypcd4 = "";
        this.srhCnslTypCd4 = [];
        this.srhCnslTypCd4.push({ CD_NM: "전체", CD: "" });
        if (spst_counsel_typ_cd !== "") {
          this.srhCnslTypCd4 = await this.mixin_counsel_type_code_get(
            asp_cust_key,
            spst_counsel_typ_cd,
            "전체"
          );
        }
      }
    },
    //상세정보 상담유형 세팅
    async changeDetailCounselType(
      asp_cust_key,
      spst_counsel_typ_cd,
      data_type
    ) {
      if (data_type === "1") {
        this.detailSrhCnslTypCd2 = [];
        this.detailSrhCnslTypCd3 = [];
        this.detailSrhCnslTypCd4 = [];
        this.detailSrhCnslTypCd2.push({ CD_NM: "전체", CD: "" });
        this.detailSrhCnslTypCd3.push({ CD_NM: "전체", CD: "" });
        this.detailSrhCnslTypCd4.push({ CD_NM: "전체", CD: "" });
        if (spst_counsel_typ_cd !== "") {
          this.detailSrhCnslTypCd2 = await this.mixin_counsel_type_code_get(
            asp_cust_key,
            spst_counsel_typ_cd,
            "전체"
          );
        }
      } else if (data_type === "2") {
        this.detailSrhCnslTypCd3 = [];
        this.detailSrhCnslTypCd4 = [];
        this.detailSrhCnslTypCd3.push({ CD_NM: "전체", CD: "" });
        this.detailSrhCnslTypCd4.push({ CD_NM: "전체", CD: "" });
        if (spst_counsel_typ_cd !== "") {
          this.detailSrhCnslTypCd3 = await this.mixin_counsel_type_code_get(
            asp_cust_key,
            spst_counsel_typ_cd,
            "전체"
          );
        }
      } else if (data_type === "3") {
        this.detailSrhCnslTypCd4 = [];
        this.detailSrhCnslTypCd4.push({ CD_NM: "전체", CD: "" });
        if (spst_counsel_typ_cd !== "") {
          this.detailSrhCnslTypCd4 = await this.mixin_counsel_type_code_get(
            asp_cust_key,
            spst_counsel_typ_cd,
            "전체"
          );
        }
      }
    },

    showAlert(msg) {
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg: msg,
        iconClass: "svg-error-lg",
        type: "default",
      });
    },

    showConfirm() {
      if (this.rowData.length == 0) {
        this.warningAlert();
        return;
      }

      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg: "내용을 저장하시겠습니까?",
        iconClass: "svg-chk-lg",
        type: "confirm",
        callYes: this.callYes,
        callNo: this.callNo,
      });
    },
    callYes() {
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: false,
      });
      this.approval();
    },
    callNo() {
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: false,
      });
    },

    exportExcel(table_id, file_name) {
      //mixin에 셋팅된 method 호출
      this.table_id = table_id;
      this.file_name = file_name;
      this.mixin_common_exportExcel();
    },

    //년월로 변환
    yyyyMMdd: function (value) {
      // 들어오는 value 값이 공백이면 그냥 공백으로 돌려줌
      if (value == "") return "";

      // 현재 Date 혹은 DateTime 데이터를 javaScript date 타입화
      var js_date = new Date(value);

      // 연도, 월, 일 추출
      var year = js_date.getFullYear();
      var month = js_date.getMonth() + 1;
      var day = js_date.getDate();

      // 월, 일의 경우 한자리 수 값이 있기 때문에 공백에 0 처리
      if (month < 10) {
        month = "0" + month;
      }

      if (day < 10) {
        day = "0" + day;
      }

      // 최종 포맷 (ex - '2021-10-08')
      return year + "" + month + "" + day;
    },

    warningAlert(text) {
      let alertText = "상세조회후 변경 및 승인요청이가능합니다.";

      if (text != null && text != "") {
        alertText = text;
      }
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg: alertText,
        iconClass: "svg-error-lg",
        type: "default",
      });
    },
    detail(item, row) {
      //변경 전 콤보박스 값들 세팅
      this.CALL_TY = item.CALL_TY;
      this.CNSL_TYP_CD = item.CNSL_TYP_CD;
      this.CNSL_TYP_CD_2 = item.CNSL_TYP_CD_2;
      this.CNSL_TYP_CD_3 = item.CNSL_TYP_CD_3;
      this.CNSL_TYP_CD_4 = item.CNSL_TYP_CD_4;
      this.CNSL_CONT = item.CNSL_CONT;
      this.VIS_PATH = item.VIS_PATH;
      this.DISA_ITEM = item.DISA_ITEM;
      this.CNSL_BOOK = item.CNSL_BOOK;
      this.CNSL_BOOK_DT = item.CNSL_BOOK_DT;
      this.CONS_BOOK = item.CONS_BOOK;
      this.VIS_BOOK = item.VIS_BOOK;
      this.CONS_BOOK_DT = item.CONS_BOOK_DT;
      this.VIS_BOOK_DT = item.VIS_BOOK_DT;
      this.OPER_BOOK = item.OPER_BOOK;
      this.OPER_BOOK_DT = item.OPER_BOOK_DT;
      this.OPERATION = item.OPERATION;
      this.OPERATION_DT = item.OPERATION_DT;
      this.ETC = this.decode(item.ETC);
      this.OPERATION_COST = item.OPERATION_COST;
      this.CONDITION = item.CONDITION;
      this.CNSL_RESULT = item.PROC_CODE_LCLS;

      this.rowData = item;

      this.CNSL_CNTN = this.decode(this.rowData.CNSL_CNTN);
      this.RDWT_FILE_NM = this.rowData.RDWT_FILE_NM;

      this.REC_CALL = process.env.VUE_APP_IPCC_REC_URL + item.RDWT_FILE_NM + ".mp3";

      /*
      this.CNSL_LV_1_DET_LIST = this.CNSL_LV_1_LIST;
      this.CNSL_LV_2_DET_LIST = this.mixin_getLVCode(this.cmmnCdLstFUNU, this.contactDetail.CNSL_TYP_CD, '전체', "중분류", '');
      this.CNSL_LV_3_DET_LIST = this.mixin_getLVCode(this.cmmnCdLstFUNU, this.contactDetail.CNSL_TYP_CD, '전체', "소분류", this.contactDetail.CNSL_TYP_CD_2);
      */
      this.CNSL_LV_1_DET = item.CNSL_TYP_CD;
      this.getConsultTY_Detail('1');
      this.CNSL_LV_2_DET = item.CNSL_TYP_CD_2;
      this.getConsultTY_Detail('2');
      this.CNSL_LV_3_DET = item.CNSL_TYP_CD_3;
      this.getConsultTY_Detail('3');
    },

    isActiveRow(item) {
      const activeClass =
        item.CNSL_HIST_NO === this.rowData.CNSL_HIST_NO ? "active" : "";
      return activeClass;
    },

    //취소
    cancel() {
      this.SELECTROW = [];
      this.rowData = {};
    },
    async setSrhDetailCnslType() {
      this.detailSrhCnslTypCd = [];
      this.detailSrhCnslTypCd2 = [];
      this.detailSrhCnslTypCd3 = [];
      this.detailSrhCnslTypCd4 = [];
      this.detailSrhCnslTypCd = await this.mixin_counsel_type_code_get(
        this.selected_channel,
        "*",
        "전체"
      );
      this.detailSrhCnslTypCd2.push({ CD_NM: "전체", CD: "" });
      this.detailSrhCnslTypCd3.push({ CD_NM: "전체", CD: "" });
      this.detailSrhCnslTypCd4.push({ CD_NM: "전체", CD: "" });
    },

    async setSrhCnslType() {
      this.srhCnslTypCd = [];
      this.srhCnslTypCd2 = [];
      this.srhCnslTypCd3 = [];
      this.srhCnslTypCd4 = [];
      this.srhCnslTypCd = await this.mixin_counsel_type_code_get(
        this.selected_channel,
        "*",
        "전체"
      );
      this.srhCnslTypCd2.push({ CD_NM: "전체", CD: "" });
      this.srhCnslTypCd3.push({ CD_NM: "전체", CD: "" });
      this.srhCnslTypCd4.push({ CD_NM: "전체", CD: "" });
    },

    //승인요청 버튼클릭
    async approval() {
      // let head = '';
      // let dataa = '';

      this.URLData = "/api/phone/history/manage/modify";
      this.HEADER_SERVICE = "phone.history.manage";
      this.HEADER_METHOD = "modify";
      this.HEADER_TYPE = "BIZ_SERVICE";

      console.log("콜유형 변경전=" + this.CALL_TY);
      console.log("콜유형 변경후=" + this.rowData.CALL_TY);
      console.log("파일명 변경전=" + this.rowData.RDWT_FILE_NM);
      console.log("파일명 변경후=" + this.RDWT_FILE_NM);
      console.log("비고 변경전=" + this.rowData.CNSL_CNTN);
      console.log("비고 변경후=" + this.CNSL_CNTN);
      console.log("상담유형 변경전=" + this.CNSL_TYP_CD);
      console.log("상담유형 변경후=" + this.rowData.CNSL_TYP_CD);


      if (this.rowData.CNSL_CNTN != this.CNSL_CNTN) {
        this.CHNG_REQ_CNTN = "00001";
      } else {
        this.CHNG_REQ_CNTN = "00002";
      }
      // return;
      //api 호출 부분
      await api
        .post(
          this.URLData,
          {
            ASP_NEWCUST_KEY: this.rowData.ASP_NEWCUST_KEY,
            CALL_TY: this.rowData.CALL_TY,
            CNSL_TYP_CD: this.CNSL_LV_1_DET,
            CNSL_TYP_CD_2: this.CNSL_LV_2_DET,
            CNSL_TYP_CD_3: this.CNSL_LV_3_DET,
            CNSL_TYP_CD_4: this.CNSL_LV_4_DET,
            CUST_NM: this.rowData.CUST_NM,
            REQ_CSLT_ID: this.rowData.CSLT_ID,
            CNSL_HIST_NO: this.rowData.CNSL_HIST_NO,

            BF_RDWT_FILE_NM: this.rowData.RDWT_FILE_NM,
            AF_RDWT_FILE_NM: this.RDWT_FILE_NM, //after

            BF_CNSL_CNTN: this.rowData.CNSL_CNTN,
            AF_CNSL_CNTN: this.encode(this.encodeEnter(this.CNSL_CNTN)),

            CHNG_REQ_CNTN: this.CHNG_REQ_CNTN,

            BF_UN_PROC_RESN: this.PROC_MDCLS_NM, //미완료사유
            AF_UN_PROC_RESN: this.rowData.PROC_CODE_MDCLS,

            CHNG_MAN: this.$store.getters["userStore/GE_USER_ROLE"].userId,
            REG_MAN: this.$store.getters["userStore/GE_USER_ROLE"].userId,

            CENT_TY: "G", //팔레트 1.0에서도 G로 하드코딩
            REG_DTIM: this.rowData.REG_DTIM,
            TRANS_STATUS: "N",

            BF_CALL_TY: this.CALL_TY,
            AF_CALL_TY: this.rowData.CALL_TY,

            BF_CNSL_TYP_CD: this.rowData.CNSL_TYP_CD,
            BF_CNSL_TYP_CD_2: this.rowData.CNSL_TYP_CD_2,
            BF_CNSL_TYP_CD_3: this.rowData.CNSL_TYP_CD_3,
            BF_CNSL_TYP_CD_4: this.rowData.CNSL_TYP_CD_4,

            AF_CNSL_TYP_CD: this.CNSL_LV_1_DET,
            AF_CNSL_TYP_CD_2: this.CNSL_LV_2_DET,
            AF_CNSL_TYP_CD_3: this.CNSL_LV_3_DET,
            AF_CNSL_TYP_CD_4: this.CNSL_LV_4_DET,

            BF_PROC_RSLT: this.rowData.PROC_CODE_LCLS,
            AF_PROC_RSLT: this.CNSL_RESULT,

            SEQ: "",

            BF_CNSL_CONT:this.rowData.CNSL_CONT,
            BF_VIS_PATH:this.rowData.VIS_PATH,
            BF_DISA_ITEM:this.rowData.DISA_ITEM,
            BF_CNSL_BOOK:this.rowData.CNSL_BOOK,
            BF_CNSL_BOOK_DT:this.rowData.CNSL_BOOK_DT,
            BF_CONS_BOOK:this.rowData.CONS_BOOK,
            BF_VIS_BOOK:this.rowData.VIS_BOOK,
            BF_CONS_BOOK_DT:this.rowData.CONS_BOOK_DT,
            BF_VIS_BOOK_DT:this.rowData.VIS_BOOK_DT,
            BF_OPER_BOOK:this.rowData.OPER_BOOK,
            BF_OPER_BOOK_DT:this.rowData.OPER_BOOK_DT,
            BF_OPERATION:this.rowData.OPERATION,
            BF_OPERATION_DT:this.rowData.OPERATION_DT,
            BF_ETC:this.rowData.ETC,
            BF_OPERATION_COST:this.rowData.OPERATION_COST,
            BF_CONDITION:this.rowData.CONDITION,

            AF_CNSL_CONT:this.CNSL_CONT,
            AF_VIS_PATH:this.VIS_PATH,
            AF_DISA_ITEM:this.DISA_ITEM,
            AF_CNSL_BOOK:this.CNSL_BOOK,
            AF_CNSL_BOOK_DT:this.returnEmpty(this.CNSL_BOOK_DT),
            AF_CONS_BOOK:this.CONS_BOOK,
            AF_VIS_BOOK:this.VIS_BOOK,
            AF_CONS_BOOK_DT:this.returnEmpty(this.CONS_BOOK_DT),
            AF_VIS_BOOK_DT:this.returnEmpty(this.VIS_BOOK_DT),
            AF_OPER_BOOK:this.OPER_BOOK,
            AF_OPER_BOOK_DT:this.returnEmpty(this.OPER_BOOK_DT),
            AF_OPERATION:this.OPERATION,
            AF_OPERATION_DT:this.returnEmpty(this.OPERATION_DT),
            AF_ETC:this.encode(this.encodeEnter(this.ETC)),
            AF_OPERATION_COST:this.OPERATION_COST,
            AF_CONDITION:this.CONDITION,
          },
          {
            head: {
              SERVICE: this.HEADER_SERVICE,
              METHOD: this.HEADER_METHOD,
              TYPE: this.HEADER_TYPE,
              ROW_CNT: "20",
              PAGES_CNT: "1",
            },
          }
        ) //api에 로그를 남기기위한 값입력 (필수) (SERVICE, METHOD, TYPE)
        .then((response) => {
          // console.log(JSON.stringify(response.data.DATA))
          // let jsonObj = JSON.parse(JSON.stringify(response.data.DATA));
          // this.gridDataText = jsonObj;

          //console.log(JSON.stringify(response.data));
          // head = JSON.stringify(response.data.HEADER);
          // dataa = JSON.stringify(response.data.DATA);
          this.common_alert("정상 처리되었습니다.");
          this.search('N');
          this.approvalConfirm(this.rowData.CNSL_HIST_NO);
          this.success();
        }) //api 호출 성공 이후 수행
        .catch((err) => {
          this.fail();
        }); //api호출 에러 작업 수행
      // this.HEADERResult = head;
      // this.DATAResult = dataa;
      //결과값 보여주기
    },

    async search(DOWN_YN, next) {
      if (this.selected_channel == "" || this.selected_channel == undefined) {
        this.showAlert("회사를 먼저 선택해주세요.");
        return;
      }
      let channel = this.selected_channel;

      let DATA_NUM = this.pagination.rowsPerPage;
      if(DOWN_YN == 'Y'){
        if (this.gridDataText.length == 0) {
          this.showAlert("상담이력 조회 후 다운로드할 수 있습니다.");
          return;
        }
        if(this.SEL_DATA_NUM != '0' && this.SEL_DATA_NUM != '' && this.SEL_DATA_NUM != undefined){
          DATA_NUM = this.SEL_DATA_NUM;
        }
      } else {
        DATA_NUM = this.pagination.rowsPerPage;
      }
      
      if(DOWN_YN != 'Y'){
        if (next == 'next'){
          channel = this.search_channel;
        } else {
          channel = this.selected_channel;
          this.gridDataText = [];
          this.search_channel = this.selected_channel
          this.pagination.page = 1; //페이징 처리 초기화
          this.nextDisabled = true  //버튼 비활성화
        }
      }

      let head = "";
      let dataa = "";
      this.URLData = "/api/phone/history/inqire/list";
      this.HEADER_SERVICE = "phone.history.inqire";
      this.HEADER_METHOD = "list";
      this.HEADER_TYPE = "BIZ_SERVICE";

      let CHK_GROUP = this.selected_userGroup[0];
      for(let i=1;i<this.selected_userGroup.length;i++){
        CHK_GROUP = CHK_GROUP + "," + this.selected_userGroup[i];
      }

      await api
        .post(
          this.URLData,
          {
            ASP_NEWCUST_KEY: channel,
            SEARCH_DT_FROM: this.yyyyMMdd(this.dates[0]),
            SEARCH_DT_TO: this.yyyyMMdd(this.dates[1]),
            CALL_TY: this.calltypcd,
            CNSL_TYP_CD: this.MODEL_1,
            CNSL_TYP_CD_2: this.MODEL_2,
            CNSL_TYP_CD_3: this.MODEL_3,
            CNSL_TYP_CD_4: this.MODEL_4,
            CUST_ID: this.userId,
            CUST_NM: this.userNm,
            CUST_TEL_NO: this.userPhoneNo,
            TM_KIND: CHK_GROUP, //사용자그룹
            CSLT_ID: this.userId1,
            CSLT_NM: this.userNm1,
            CSLT_NICK: this.userNick1,
            PROC_CODE_LCLS: this.SRH_RESULT,
            SEARCH_TIME_0: this.CHK_NEWTIME[0],
            SEARCH_TIME_1: this.CHK_NEWTIME[1],
            SEARCH_TIME_2: this.CHK_NEWTIME[2],
            SEARCH_TIME_3: this.CHK_NEWTIME[3],
            SEARCH_TIME_4: this.CHK_NEWTIME[4],
            SEARCH_TIME_5: this.CHK_NEWTIME[5],
            SEARCH_TIME_6: this.CHK_NEWTIME[6],
            SEARCH_TIME_7: this.CHK_NEWTIME[7],
            SEARCH_TIME_8: this.CHK_NEWTIME[8],
            SEARCH_TIME_9: this.CHK_NEWTIME[9],
            SEARCH_TIME_10: this.CHK_NEWTIME[10],
            SEARCH_TIME_11: this.CHK_NEWTIME[11],
            SEARCH_TIME_12: this.CHK_NEWTIME[12],
            SEARCH_TIME_13: this.CHK_NEWTIME[13],
            SEARCH_TIME_14: this.CHK_NEWTIME[14],
            SEARCH_TIME_15: this.CHK_NEWTIME[15],
            SEARCH_TIME_16: this.CHK_NEWTIME[16],
            SEARCH_TIME_17: this.CHK_NEWTIME[17],
            SEARCH_TIME_18: this.CHK_NEWTIME[18],
            SEARCH_TIME_19: this.CHK_NEWTIME[19],
            SEARCH_TIME_20: this.CHK_NEWTIME[20],
            SEARCH_TIME_21: this.CHK_NEWTIME[21],
            SEARCH_TIME_22: this.CHK_NEWTIME[22],
            SEARCH_TIME_23: this.CHK_NEWTIME[23],
            RANGE: this.SEL_NEWUPDOWN,
            REC_TIME: this.CALL_TIME,

            CATEGORY: this.SRH_CATEGORY,
            CNSL_CHN_1: this.SRH_CNSL_CHN_1,
            CNSL_BOOK: this.SRH_CNSL_BOOK,
            DISA_ITEM: this.SRH_DISA_ITEM,
            CONDITION: this.SRH_CONDITION,

            VALUE_1: this.VALUE_1,
            VALUE_2: this.VALUE_2,
            VALUE_3: this.VALUE_3,
            VALUE_4: this.VALUE_4,
            VALUE_5: this.VALUE_5,
            VALUE_6: this.VALUE_6,
            VALUE_7: this.VALUE_7,
            VALUE_8: this.VALUE_8,
            VALUE_9: this.VALUE_9,
            VALUE_10: this.VALUE_10,

            MODEL_1: this.MODEL_1,
            MODEL_2: this.MODEL_2,
            MODEL_3: this.MODEL_3,
            MODEL_4: this.MODEL_4,
            MODEL_5: this.MODEL_5,
            MODEL_6: this.MODEL_6,
            MODEL_7: this.MODEL_7,
            MODEL_8: this.MODEL_8,
            MODEL_9: this.MODEL_9,
            MODEL_10: this.MODEL_10,

            CMM_1: this.CMM_1,
            CMM_2: this.CMM_2,
            CMM_3: this.CMM_3,
            CMM_4: this.CMM_4,
            CMM_5: this.CMM_5,
            CMM_6: this.CMM_6,
            CMM_7: this.CMM_7,
            CMM_8: this.CMM_8,
            CMM_9: this.CMM_9,
            CMM_10: this.CMM_10,
          },
          {
            head: {
              SERVICE: this.HEADER_SERVICE,
              METHOD: this.HEADER_METHOD,
              TYPE: this.HEADER_TYPE,
              ROW_CNT: DATA_NUM,
              PAGES_CNT: DOWN_YN == 'Y'? '1' : this.pagination.page,
              PAGING: 'Y',
            },
          }
        )
        .then((response) => {
          this.rowData = [];

          let jsonObj = JSON.parse(JSON.stringify(response.data.DATA));

          if(DOWN_YN == 'Y'){
            this.downGridDataText = jsonObj;
          } else {
            // this.gridDataText = jsonObj;            
            this.gridDataText = [...this.gridDataText, ...jsonObj]   

            //다음
            console.log('response.data.HEADER.next=',response.data.HEADER.next)
            //request에 보낸 head.PAGES_CNT와 head.ROW_CNT을 곱한값과 response.data.HEADER의 HEADER.TOT_COUNT와 비교하여 다음 조회건이 있는지 확인한다
            if(response.data.HEADER.next !== null && response.data.HEADER.next !== undefined){
              if(response.data.HEADER.next === true){
                this.nextDisabled = false //버튼 활성화
              }else{
                this.nextDisabled = true  //버튼 비활성화
              }
            }

            //이부분은 체크해볼것
            this.pagination.page += 1 
            //나중에 그리드를 초기화시에는 this.pagination.page를 1로 바꿔줘야 함
          }

          this.SELECTROW = [];

          head = JSON.stringify(response.data.HEADER);
          dataa = JSON.stringify(response.data.DATA);
          
        })
        .catch((err) => {
          alert(err);
        });
      this.HEADERResult = head;
      this.DATAResult = dataa;

      if(DOWN_YN == 'Y'){
        this.exportExcel('downTableMng', '상담이력조회 목록');
      }
    },

    success() {
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg: "정상처리 되었습니다.",
        iconClass: "svg-done-lg",
        type: "default",
      });
    },

    fail() {
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg: "시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.",
        iconClass: "svg-error-lg",
        type: "default",
      });
    },

    
    chgChkTime(){
      let TimeArray = this.CHK_NEWTIME;
      this.CHK_NEWTIME = [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ]
      for(let i = 0; i < TimeArray.length; i++){
        if(TimeArray[i] != "" || TimeArray[i] != null){
          let m = parseInt(TimeArray[i]);
          this.CHK_NEWTIME[m] = TimeArray[i];
        }
      }
     this.CHK_NEWTIME.splice(24,1);
    },

    removeDropTime(item) {
      this.CHK_NEWTIME.splice(parseInt(item.value), 1);

      let TimeArray = this.CHK_NEWTIME;
      this.CHK_NEWTIME = [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ]
      for(let i = 0; i < TimeArray.length; i++){
        if(TimeArray[i] != "" || TimeArray[i] != null){
          let m = parseInt(TimeArray[i]);
          this.CHK_NEWTIME[m] = TimeArray[i];
        }
      }

      this.chkNoTime();
    },

    allClick() {
      this.CHK_NEWTIME = [
        "00",
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
      ];
    },

    allCloseClick() {
      this.CHK_NEWTIME = [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ];
    },

    async selectTime() {
      if (this.ASP_NEWCUST_KEY == "" || this.ASP_NEWCUST_KEY == undefined) {
        this.showAlert("회사를 먼저 선택해주세요.");
        return;
      }
      await api
        .post(
          "api/statistics/chat/common/start-job-time/inqire", //api url입력
          {
            ASP_NEWCUST_KEY: this.ASP_NEWCUST_KEY,
            ASP_USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
            ASP_CUST_KEY:
              this.$store.getters["userStore/GE_USER_ROLE"].company[0].CD,
          },
          {
            head: {
              SERVICE: "statistics.chat.common.start-job-time",
              METHOD: "inqire",
              TYPE: "BIZ_SERVICE",
            },
          }
        )
        .then((response) => {
          var ST_TIME = response.data.DATA[0].WORK_START_TIME;

          var ST_HOUR = ST_TIME.substr(0, 2);
          this.ST_JOB_TIME = ST_HOUR;
        })
        .catch((err) => {
          alert(err);
        });

      await api
        .post(
          "api/statistics/chat/common/end-job-time/inqire", //api url입력
          {
            ASP_NEWCUST_KEY: this.ASP_NEWCUST_KEY,
            ASP_USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
            ASP_CUST_KEY:
              this.$store.getters["userStore/GE_USER_ROLE"].company[0].CD,
          },
          {
            head: {
              SERVICE: "statistics.chat.common.end-job-time",
              METHOD: "inqire",
              TYPE: "BIZ_SERVICE",
            },
          }
        )
        .then((response) => {
          var END_TIME = response.data.DATA[0].WORK_END_TIME;

          var END_HOUR = END_TIME.substr(0, 2);
          this.END_JOB_TIME = END_HOUR;
        })
        .catch((err) => {
          alert(err);
        });

      this.CHK_NEWTIME = [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ];

      for (
        var i = parseInt(this.ST_JOB_TIME);
        i <= parseInt(this.END_JOB_TIME);
        i++
      ) {
        if (i < 10) {
          let DB_TIME = "0" + i;
          this.CHK_NEWTIME[i] = DB_TIME;
        } else {
          this.CHK_NEWTIME[i] = i + "";
        }
      }
    },

    selectTurn() {
      var NEWTIME = [];

      for (var i = 0; i < 24; i++) {
        NEWTIME[i] = this.CHK_NEWTIME[i];
        if (i < 10) {
          if (NEWTIME[i] != "") {
            NEWTIME[i] = "";
          } else if (NEWTIME[i] == "") {
            let DB_TIME = "0" + i;
            NEWTIME[i] = DB_TIME;
          }
        } else {
          if (NEWTIME[i] != "") {
            NEWTIME[i] = "";
          } else if (NEWTIME[i] == "") {
            NEWTIME[i] = i + "";
          }
        }
      }
      this.CHK_NEWTIME = [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ];
      this.CHK_NEWTIME = NEWTIME;
      
    },

    approvalConfirm(CNSL_HIST_NO){
      let today = new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10);
      api.post("/api/phone/history/change-manage/list",
      {
        ASP_NEWCUST_KEY: this.selected_channel,
        SEARCH_DT_FROM: this.yyyyMMdd(today),
        SEARCH_DT_TO: this.yyyyMMdd(today),
        CHNG_PROC_TY: "00001",
        CNSL_HIST_NO: CNSL_HIST_NO,
      },
      {
        head: {
          SERVICE: "phone.history.change-manage",
          METHOD: "list",
          TYPE: "XML_SERVICE",
          ROW_CNT: "50",
          PAGES_CNT: "1",
        },
      })
      .then((response) => {
          console.log("데이터 : " + JSON.stringify(response.data.DATA));

          this.approvalList = response.data.DATA;

          this.saveApproval();
        })
        .catch((err) => {
          alert(err);
        });
    },

    async saveApproval(){
      let chkList = this.approvalList;

      let URLData = "/api/phone/history/change-manage/confm/process";
      let HEADER_SERVICE = "phone.history.change-manage.confm";
      let HEADER_METHOD = "process";
      let HEADER_TYPE = "BIZ_SERVICE";

      for (let i in chkList) {
        let jsonData = chkList[i];
        jsonData.APRV_CSLT_ID =
          this.$store.getters["userStore/GE_USER_ROLE"].userId;

        await api
          .post(URLData, jsonData, {
            head: {
              SERVICE: HEADER_SERVICE,
              METHOD: HEADER_METHOD,
              TYPE: HEADER_TYPE,
            },
          })
          .then((response) => {})
          .catch((err) => {
            console.log("err = " + err);
          });
      }
    },

    getCmmCode(){
      this.CNSL_CONT_LIST = this.mixin_common_code_byCust(this.cmmnCdLstByCus, 'MTC001','전체',this.selected_channel);
      this.VIS_PATH_LIST = this.mixin_common_code_byCust(this.cmmnCdLstByCus, 'MTC002','전체',this.selected_channel);
      this.DISA_ITEM_LIST = this.mixin_common_code_byCust(this.cmmnCdLstByCus, 'MTC003','전체',this.selected_channel);
      this.CNSL_BOOK_LIST = this.mixin_common_code_byCust(this.cmmnCdLstByCus, 'MTC004','전체',this.selected_channel);
      this.CONDITION_LIST = this.mixin_common_code_byCust(this.cmmnCdLstByCus, 'MTC005','전체',this.selected_channel);
      this.CONS_BOOK_LIST = this.mixin_common_code_byCust(this.cmmnCdLstByCus, 'MTC006','전체',this.selected_channel);
      this.VIS_BOOK_LIST = this.mixin_common_code_byCust(this.cmmnCdLstByCus, 'MTC007','전체',this.selected_channel);
      this.OPER_BOOK_LIST = this.mixin_common_code_byCust(this.cmmnCdLstByCus, 'MTC008','전체',this.selected_channel);
      this.CATEGORY_LIST = this.mixin_common_code_byCust(this.cmmnCdLstByCus, 'MTC009','전체',this.selected_channel);
      this.OPERATION_LIST = this.mixin_common_code_byCust(this.cmmnCdLstByCus, 'MTC010','전체',this.selected_channel);
      this.OPER_MANAGE_LIST = this.mixin_common_code_byCust(this.cmmnCdLstByCus, 'MTC010','전체',this.selected_channel);  //리스트 추가해야함 (공통코드인지 다른 리스트인지)
      this.VIS_MANAGE_LIST = this.mixin_common_code_byCust(this.cmmnCdLstByCus, 'MTC010','전체',this.selected_channel);   //리스트 추가해야함 (공통코드인지 다른 리스트인지)
      this.MANAGE_LIST = this.mixin_common_code_byCust(this.cmmnCdLstByCus, 'MTC010','전체',this.selected_channel);       //리스트 추가해야함 (공통코드인지 다른 리스트인지)
      this.CNSL_CHN_1_LIST = this.mixin_common_code_byCust(this.cmmnCdLstByCus, 'MTC012','전체',this.selected_channel);   
      //this.CNSL_CHN_2_LIST = this.common_code_byCust(this.cmmnCdLstByCus, 'MTC013','전체',this.selected_channel);       
    },

    chgCnslLv(CHK){
      if(CHK == '중분류'){
        this.CNSL_LV_2_LIST=[];
        this.ITEM_2=[];
        this.CNSL_LV_2='';
        this.MODEL_2='';
        this.CNSL_LV_3_LIST=[];
        this.ITEM_3=[{text:'전체',value:''}];
        this.CNSL_LV_3='';
        this.MODEL_3='';
        this.CNSL_LV_2_LIST = this.mixin_getLVCode(this.cmmnCdLstFUNU, this.MODEL_1, '전체', CHK, '');
        this.ITEM_2 = this.CNSL_LV_2_LIST;
      } else if (CHK == '소분류'){
        this.CNSL_LV_3_LIST=[];
        this.ITEM_3=[];
        this.CNSL_LV_3='';
        this.MODEL_3='';
        this.CNSL_LV_3_LIST = this.mixin_getLVCode(this.cmmnCdLstFUNU, this.MODEL_1, '전체', CHK, this.MODEL_2);
        this.ITEM_3 = this.CNSL_LV_3_LIST;
      }
    },

    chgCnslLvDetail(CHK){
      if(CHK == '중분류'){
        this.CNSL_LV_2_DET_LIST=[];
        this.CNSL_LV_2_DET='';
        this.CNSL_LV_3_DET_LIST=[];
        this.CNSL_LV_3_DET='';
        this.CNSL_LV_2_DET_LIST = this.mixin_getLVCode(this.cmmnCdLstFUNU, this.CNSL_LV_1_DET, '전체', CHK, '');
      } else if (CHK == '소분류'){
        this.CNSL_LV_3_DET_LIST=[];
        this.CNSL_LV_3_DET='';
        this.CNSL_LV_3_DET_LIST = this.mixin_getLVCode(this.cmmnCdLstFUNU, this.CNSL_LV_1_DET, '전체', CHK, this.CNSL_LV_2_DET);
      }
    },
    
    cngSelMenu(){
      if(this.selected_channel == 'MOMO' || this.selected_channel == 'DIET' || this.selected_channel == 'LITING' || this.selected_channel == 'PLAN'){
        this.SEL_1 = true;
        this.SEL_2 = true;
        this.SEL_3 = true;
        this.SEL_4 = true;

        this.SEL_8 = false;

        this.TEXT_1 = '상담유형1';
        this.TEXT_2 = '상담유형2';
        this.TEXT_3 = '상담유형3';
        this.TEXT_4 = '상담유형4';
        this.ITEM_1 = this.CNSL_LV_1_LIST;
        this.ITEM_2 = this.CNSL_LV_2_LIST;
        this.ITEM_3 = this.CNSL_LV_3_LIST;
        this.ITEM_4 = this.CNSL_LV_4_LIST;
        this.ITEM_TEXT_1 = 'CD_NM';
        this.ITEM_TEXT_2 = 'CD_NM';
        this.ITEM_TEXT_3 = 'CD_NM';
        this.ITEM_TEXT_4 = 'CD_NM';
        this.ITEM_VALUE_1 = 'CD';
        this.ITEM_VALUE_2 = 'CD';
        this.ITEM_VALUE_3 = 'CD';
        this.ITEM_VALUE_4 = 'CD';
        this.MODEL_1 = this.CNSL_LV_1;
        this.MODEL_2 = this.CNSL_LV_2;
        this.MODEL_3 = this.CNSL_LV_3;
        this.MODEL_4 = this.CNSL_LV_4;
        
        this.CHG_1 = "";
        this.CHG_2 = "";
        this.SEL_5 = true;
        this.SEL_6 = true;
        this.SEL_7 = true;
        this.SEL_9 = true;
        this.SEL_10 = true;
        this.TEXT_5 = '카테고리';
        this.TEXT_6 = '상담채널';
        this.TEXT_7 = '문의유형';
        this.TEXT_9 = '진료항목';
        this.TEXT_10 = '상태';
        this.ITEM_5 = this.CATEGORY_LIST;
        this.ITEM_6 = this.CNSL_CHN_1_LIST;
        this.ITEM_7 = this.CNSL_BOOK_LIST;
        this.ITEM_9 = this.DISA_ITEM_LIST;
        this.ITEM_10 = this.CONDITION_LIST;
        this.ITEM_TEXT_5 = 'text';
        this.ITEM_TEXT_6 = 'text';
        this.ITEM_TEXT_7 = 'text';
        this.ITEM_TEXT_9 = 'text';
        this.ITEM_TEXT_10 = 'text';
        this.ITEM_VALUE_5 = 'value';
        this.ITEM_VALUE_6 = 'value';
        this.ITEM_VALUE_7 = 'value';
        this.ITEM_VALUE_9 = 'value';
        this.ITEM_VALUE_10 = 'value';
        this.MODEL_5 = this.SRH_CATEGORY;
        this.MODEL_6 = this.SRH_CNSL_CHN_1;
        this.MODEL_7 = this.SRH_CNSL_BOOK;
        this.MODEL_9 = this.SRH_DISA_ITEM;
        this.MODEL_10 = this.SRH_CONDITION;
        this.VALUE_5 = 'CATEGORY';
        this.VALUE_6 = 'CNSL_CHN_1';
        this.VALUE_7 = 'CNSL_BOOK';
        this.VALUE_9 = 'DISA_ITEM';
        this.VALUE_10 = 'CONDITION';
        this.CMM_5 = 'MTC009';
        this.CMM_6 = 'MTC012';
        this.CMM_7 = 'MTC004';
        this.CMM_9 = 'MTC003';
        this.CMM_10 = 'MTC005';
      } else if(this.selected_channel == 'FUNU'){
        this.SEL_1 = true;
        this.SEL_2 = true;
        this.SEL_3 = true;
        this.SEL_4 = false;
        this.SEL_5 = false;
        this.SEL_6 = false;
        this.SEL_7 = false;
        this.SEL_8 = false;
        this.TEXT_1 = '상담유형1';
        this.TEXT_2 = '상담유형2';
        this.TEXT_3 = '상담유형3';
        this.ITEM_1 = this.CNSL_LV_1_LIST;
        this.ITEM_2 = this.CNSL_LV_2_LIST;
        this.ITEM_3 = this.CNSL_LV_3_LIST;
        this.ITEM_TEXT_1 = 'CD_NM';
        this.ITEM_TEXT_2 = 'CD_NM';
        this.ITEM_TEXT_3 = 'CD_NM';
        this.ITEM_VALUE_1 = 'CD';
        this.ITEM_VALUE_2 = 'CD';
        this.ITEM_VALUE_3 = 'CD';
        this.MODEL_1 = this.CNSL_LV_1;
        this.MODEL_2 = this.CNSL_LV_2;
        this.MODEL_3 = this.CNSL_LV_3;
        this.CHG_1 = "중분류";
        this.CHG_2 = "소분류";
      } else {
        this.SEL_1 = false;
        this.SEL_2 = false;
        this.SEL_3 = false;
        this.SEL_4 = false;
        this.SEL_5 = false;
        this.SEL_6 = false;
        this.SEL_7 = false;
        this.SEL_8 = false;
        this.SEL_9 = false;
        this.SEL_10 = false;
      }
    },

    //
    //상담유형 사용
    //상담유형 select 불러오기
    getConsultTY(consult){
      var box = '';
      this.CNSL_LV_1 = this.MODEL_1;
      this.CNSL_LV_2 = this.MODEL_2;
      this.CNSL_LV_3 = this.MODEL_3;
      this.CNSL_LV_4 = this.MODEL_4;
      if(consult == "*") {
        this.CNSL_LV_1_LIST = [{CD:'', CD_NM:'전체'}]
        this.CNSL_LV_1 = '';
        this.CNSL_LV_2_LIST = [{CD:'', CD_NM:'전체'}]
        this.CNSL_LV_2 = '';
        this.CNSL_LV_3_LIST = [{CD:'', CD_NM:'전체'}]
        this.CNSL_LV_3 = '';
        this.CNSL_LV_4_LIST = [{CD:'', CD_NM:'전체'}]
        this.CNSL_LV_4 = '';
        box = consult;
      } else if(consult == "1"){
        this.CNSL_LV_2_LIST = [{CD:'', CD_NM:'전체'}]
        this.CNSL_LV_2 = '';
        this.CNSL_LV_3_LIST = [{CD:'', CD_NM:'전체'}]
        this.CNSL_LV_3 = '';
        this.CNSL_LV_4_LIST = [{CD:'', CD_NM:'전체'}]
        this.CNSL_LV_4 = '';
        box = this.CNSL_LV_1;
      } else if(consult == "2"){
        this.CNSL_LV_3_LIST = [{CD:'', CD_NM:'전체'}]
        this.CNSL_LV_3 = '';
        this.CNSL_LV_4_LIST = [{CD:'', CD_NM:'전체'}]
        this.CNSL_LV_4 = '';
        box = this.CNSL_LV_2;
      } else if(consult == "3"){
        this.CNSL_LV_4_LIST = [{CD:'', CD_NM:'전체'}]
        this.CNSL_LV_4 = '';
        box = this.CNSL_LV_3;
      }
      api.post("api/setting/consulttype/manage/inqire",   //api url입력
      {
        ASP_NEWCUST_KEY: this.selected_channel,
        SPST_CNSL_TYP_CD: box,
        USE_YN: "Y",
        ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
        ASP_CUST_KEY: this.$store.getters['userStore/GE_USER_ROLE'].company[0].CD,
      },
      {head: {
        "SERVICE" : "api.palette-main.aspcustkey",
        "METHOD" : "inqire",
        "TYPE" : "BIZ_SERVICE",
      }})
      .then((response) => {
        console.log("상담 시작", response.data);
        if(consult == "*") {
          this.CNSL_LV_1_LIST = [...this.CNSL_LV_1_LIST, ...response.data.DATA];
        } else if(consult == "1"){
          if (this.CNSL_LV_1 == ''){
            this.CNSL_LV_2_LIST = [{CD:'', CD_NM:'전체'}]
            this.CNSL_LV_2 = '';
            this.CNSL_LV_3_LIST = [{CD:'', CD_NM:'전체'}]
            this.CNSL_LV_3 = '';
            this.CNSL_LV_4_LIST = [{CD:'', CD_NM:'전체'}]
            this.CNSL_LV_4 = '';
          } else {
            this.CNSL_LV_2_LIST = [...this.CNSL_LV_2_LIST, ...response.data.DATA];
          }
        } else if(consult == "2"){
          if (this.CNSL_LV_2 == ''){
            this.CNSL_LV_3_LIST = [{CD:'', CD_NM:'전체'}]
            this.CNSL_LV_3 = '';
            this.CNSL_LV_4_LIST = [{CD:'', CD_NM:'전체'}]
            this.CNSL_LV_4 = '';
          }else {
            this.CNSL_LV_3_LIST = [...this.CNSL_LV_3_LIST, ...response.data.DATA];
          }
        } else if(consult == "3"){
          if (this.CNSL_LV_3 == ''){
            this.CNSL_LV_4_LIST = [{CD:'', CD_NM:'전체'}]
            this.CNSL_LV_4 = '';
          }else {
            this.CNSL_LV_4_LIST = [...this.CNSL_LV_4_LIST, ...response.data.DATA];
          }
        }
        this.ITEM_1 = this.CNSL_LV_1_LIST;
        this.ITEM_2 = this.CNSL_LV_2_LIST;
        this.ITEM_3 = this.CNSL_LV_3_LIST;
        this.ITEM_4 = this.CNSL_LV_4_LIST;
      })
      .catch((err) => {
        alert(err);
      })
    },

    //상세정보테이블
    getConsultTY_Detail(consult){
      console.log("hererere");
      var box = '';
      if(consult == "*") {
        this.CNSL_LV_1_DET_LIST = [{CD:'', CD_NM:'전체'}]
        this.CNSL_LV_1_DET = '';
        this.CNSL_LV_2_DET_LIST = [{CD:'', CD_NM:'전체'}]
        this.CNSL_LV_2_DET = '';
        this.CNSL_LV_3_DET_LIST = [{CD:'', CD_NM:'전체'}]
        this.CNSL_LV_3_DET = '';
        this.CNSL_LV_4_DET_LIST = [{CD:'', CD_NM:'전체'}]
        this.CNSL_LV_4_DET = '';
        box = consult;
      } else if(consult == "1"){
        this.CNSL_LV_2_DET_LIST = [{CD:'', CD_NM:'전체'}]
        this.CNSL_LV_2_DET = '';
        this.CNSL_LV_3_DET_LIST = [{CD:'', CD_NM:'전체'}]
        this.CNSL_LV_3_DET = '';
        this.CNSL_LV_4_DET_LIST = [{CD:'', CD_NM:'전체'}]
        this.CNSL_LV_4_DET = '';
        box = this.CNSL_LV_1_DET;
      } else if(consult == "2"){
        this.CNSL_LV_3_DET_LIST = [{CD:'', CD_NM:'전체'}]
        this.CNSL_LV_3_DET = '';
        this.CNSL_LV_4_DET_LIST = [{CD:'', CD_NM:'전체'}]
        this.CNSL_LV_4_DET = '';
        box = this.CNSL_LV_2_DET;
      } else if(consult == "3"){
        this.CNSL_LV_4_DET_LIST = [{CD:'', CD_NM:'전체'}]
        this.CNSL_LV_4_DET = '';
        box = this.CNSL_LV_3_DET;
      }
      api.post("api/setting/consulttype/manage/inqire",   //api url입력
      {
        ASP_NEWCUST_KEY: this.selected_channel,
        SPST_CNSL_TYP_CD: box,
        USE_YN: "Y",
        ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
        ASP_CUST_KEY: this.$store.getters['userStore/GE_USER_ROLE'].company[0].CD,
      },
      {head: {
        "SERVICE" : "api.palette-main.aspcustkey",
        "METHOD" : "inqire",
        "TYPE" : "BIZ_SERVICE",
      }})
      .then((response) => {
        console.log("상담 시작", response.data);
        if(consult == "*") {
          this.CNSL_LV_1_DET_LIST = [...this.CNSL_LV_1_DET_LIST, ...response.data.DATA];
        } else if(consult == "1"){
          if (this.CNSL_LV_1_DET == ''){
            this.CNSL_LV_2_DET_LIST = [{CD:'', CD_NM:'전체'}]
            this.CNSL_LV_2_DET = '';
            this.CNSL_LV_3_DET_LIST = [{CD:'', CD_NM:'전체'}]
            this.CNSL_LV_3_DET = '';
            this.CNSL_LV_4_DET_LIST = [{CD:'', CD_NM:'전체'}]
            this.CNSL_LV_4_DET = '';
          } else {
            this.CNSL_LV_2_DET_LIST = [...this.CNSL_LV_2_DET_LIST, ...response.data.DATA];
          }
        } else if(consult == "2"){
          if (this.CNSL_LV_2_DET == ''){
            this.CNSL_LV_3_DET_LIST = [{CD:'', CD_NM:'전체'}]
            this.CNSL_LV_3_DET = '';
            this.CNSL_LV_4_DET_LIST = [{CD:'', CD_NM:'전체'}]
            this.CNSL_LV_4_DET = '';
          }else {
            this.CNSL_LV_3_DET_LIST = [...this.CNSL_LV_3_DET_LIST, ...response.data.DATA];
          }
        } else if(consult == "3"){
          if (this.CNSL_LV_3_DET == ''){
            this.CNSL_LV_4_DET_LIST = [{CD:'', CD_NM:'전체'}]
            this.CNSL_LV_4_DET = '';
          }else {
            this.CNSL_LV_4_DET_LIST = [...this.CNSL_LV_4_DET_LIST, ...response.data.DATA];
          }
        }
      })
      .catch((err) => {
        alert(err);
      })
    },

    //상담유형 사용
    //

    //시간 빈값 넣기
    returnEmpty(time){
      if(time.endsWith('00:00')){
        return '';
      } else {
        return time;
      }
    },

    
    //소속정보를 가져와 사용자 그룹 셀렉트 박스 만들기
    async getUserGroup() {
      this.userGroup = await this.mixin_attr_get(this.selected_channel, "B", "");
      let userGroupLeng = this.userGroup.length;
      let i = 0;
      this.selected_userGroup = [];
      for (i;i<userGroupLeng;i++){
        this.selected_userGroup[i] = this.userGroup[i].CD;
      }
    },

    removeDropGroup(index) {
      this.selected_userGroup.splice(index, 1);
    },

    
      /**
       * XSS Encode
       */  
      encode(strText) {

        if (strText === "" || strText == null) {
          return strText;
        }

        //strText = strText.toString();
        
        strText = strText.replaceAll("(", "&#40;");
        strText = strText.replaceAll(")", "&#41;");
        strText = strText.replaceAll("[", "&#91;");
        strText = strText.replaceAll("]", "&#93;");
        strText = strText.replaceAll("&", "&amp;");
        strText = strText.replaceAll('"', "&quot;");
        strText = strText.replaceAll("'", "&apos;");
        
        strText = strText.replaceAll("\r\n", "<br>");
        strText = strText.replaceAll("\r", "<br>");
        strText = strText.replaceAll("\n", "<p>");
        
        strText = strText.replaceAll("<", "&lt;");
        strText = strText.replaceAll(">", "&gt;");
        

        return strText;
    },

    /**
     * XSS Encode 엔터키 
     */ 
    encodeEnter(strText) {

        if (strText === "" || strText == null) {
          return strText;
        }

        //strText = strText.toString();
        
        strText = strText.replaceAll("\r\n", "<br>");
        strText = strText.replaceAll("\r", "<br>");
        strText = strText.replaceAll("\n", "<br>");
        
        return strText;
    },

    /**
     * XSS Decode
     */
    decode(strText) {

      //값존재유무 체크
      if (strText === "" || strText == null) {
        return strText;
      }
      strText = strText.toString();

      //문자열 길이가 4이상일 경우만 처리
      if (strText.length <= 3) {
        return strText;
      }

      strText = strText.replaceAll("&lt;", "<");
      strText = strText.replaceAll("&gt;", ">");
      strText = strText.replaceAll("&#40;", "(");
      strText = strText.replaceAll("&#41;", ")");
      strText = strText.replaceAll("&#91;", "[");
      strText = strText.replaceAll("&#93;", "]");
      strText = strText.replaceAll("&amp;", "&");
      strText = strText.replaceAll("&quot;", '"');
      strText = strText.replaceAll("&apos;", "'");
      strText = strText.replaceAll("<br>", "\r");
      strText = strText.replaceAll("<p>", "\n");

      return strText;
    },

  },
  computed: {
    dateRangeText() {
      var newStartDate = this.dates[0];
      var newEndDate = this.dates[1];
      return newStartDate + " ~ " + newEndDate;
    },
    CNSL_BOOK_DT:{
      get(){
      return `${this.date1} ${this.timeStep1}`;
      },
      set(){

      }
    },
    CONS_BOOK_DT: {
      get(){
      return `${this.date2} ${this.timeStep2}`;
      },
      set(){

      }
    },
    VIS_BOOK_DT: {
      get(){
      return `${this.date2} ${this.timeStep2}`;
      },
      set(){

      }
    },
    OPER_BOOK_DT: {
      get(){
      return `${this.date3} ${this.timeStep3}`;
      },
      set(){

      }
    },
    OPERATION_DT: {
      get(){
      return `${this.date4} ${this.timeStep4}`;
      },
      set(){

      }
    },
    popupParams() {
      return (this.params = {
        userId: this.userId,
        userNm: this.userNm,
        asp_cust_key: this.selected_channel,
      });
    },
    ...mapGetters({
      aspCustList: "userStore/GE_USER_COMPANY",
    }),
  },
  watch: {
    async rowData() {
      console.log(
        "call rowData!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
        this.rowData
      );
      this.setSrhDetailCnslType();
      if (
        this.rowData.CNSL_TYP_CD != "" &&
        this.rowData.CNSL_TYP_CD != undefined
      ) {
        this.changeDetailCounselType(
          this.selected_channel,
          this.rowData.CNSL_TYP_CD,
          "1"
        );
        if (
          this.rowData.CNSL_TYP_CD_2 != "" &&
          this.rowData.CNSL_TYP_CD_2 != undefined
        ) {
          this.changeDetailCounselType(
            this.selected_channel,
            this.rowData.CNSL_TYP_CD_2,
            "2"
          );
          if (
            this.rowData.CNSL_TYP_CD_3 != "" &&
            this.rowData.CNSL_TYP_CD_3 != undefined
          ) {
            this.changeDetailCounselType(
              this.selected_channel,
              this.rowData.CNSL_TYP_CD_3,
              "3"
            );
          }
        }
      }
    },
  },
};
</script>

<style></style>
